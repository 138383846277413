import React, { useState, useEffect, useRef } from 'react';
import I18n from '../../helper/Localization';
import { TableView } from './TableView';
import styled from 'styled-components';
import History from '../BrowserHistory';
import { ContextMenu } from '../ContextMenu';
import { InfoButton } from '../InfoButton';
import { StartButton } from '../StartButton';
import { TestStatusTeachingBubbleContent } from '../TestStatusTeachingBubbleContent';
import { StatusIcon } from '../StatusIcon';
import { postToApi, postToApiBlob } from '../../helper/ApiHelper';
import OrderDetailDialog from '../dialogs/orderDetail/OrderDetailDialog';
import { getTheme } from 'office-ui-fabric-react';
import { useStoreState } from 'easy-peasy';
import { isUserAccountManager, isUserUser } from '../../helper/RoleHelper';
import { BasicDialog } from '../dialogs/BasicDialog';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { ManualTestDialog } from '../dialogs/ManualTestDialog';
import { toFormattedDateTime, toFormattedDate } from '../../helper/DateFormatHelper';
import { Mono } from '../../styles/Globals';
import { Colors } from '../../styles/Globals';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
`;

const OrderViewContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const InactiveText = styled.div`
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: ${Colors.inactiveGray};
  opacity: 1;
`;

const SortableTableHeader = styled.div`
  cursor: pointer;
`;

/**
 * Component displaying the orders in a table.
 */
export const OrdersView = () => {
  const [orderPositions, setOrderPositions] = useState([]);
  const [isAbortDialogOpen, setAbortDialogOpen] = useState(false);
  const [orderDetailHidden, setOrderDetailHidden] = useState(true);
  const [isManualTestDialogOpen, setManualTestDialogOpen] = useState(false);
  const [selectedOrderPosition, setSelectedOrderPosition] = useState(undefined);
  const userRoles = useStoreState((state) => state.user.roles);
  const isAccountManager = isUserAccountManager(userRoles);
  const isUser = isUserUser(userRoles);
  /**
   * visible state for the busy indicator
   */
  const [isBusy, setIsBusy] = useState(false);

  /**
   * Whether the table search is busy or not.
   */
  const [isSearchBusy, setIsSearchBusy] = useState(false);

  /**
   * Whether the pdf download is busy or not.
   */
  const [isDownloadBusy, setIsDownloadBusy] = useState(false);

  /**
   * Fluent ui theme.
   */
  const theme = getTheme();

  /** Request abort controller. */
  const abortController = new AbortController();

  /**
   * The maximum number of pages of the current fetched data.
   */
  const [maxPages, setMaxPages] = useState(1);

  /**
   * The current selected data page.
   */
  const [currentPage, setCurrentPage] = useState(1);

  /**
   * State of the current sort direction. true for ASC, false for DESC.
   */
  const [sortAscending, setSortAscending] = useState(true);

  /**
   * State of the current sort property.
   */
  const [sortPropertyName, setSortPropertyName] = useState('OrderDeliveryInvoiceNumber');

  /**
   * State of the current sort property.
   */
  const [searchValue, setSearchValue] = useState('');

  /**
   * Whether the user has sorted the table or not.
   */
  const hasSorted = useRef(false);

  const previousSearchValue = useRef('');

  /**
   * Load debtor on component startup
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsBusy(true);
        const defaultReqBody = {
          itemsPerPage: 25,
          sortDirection: sortAscending ? 1 : 2,
          sortPropertyName: sortPropertyName
        };
        let reqBody;
        if (searchValue && searchValue !== '') {
          setIsSearchBusy(true);
          if (searchValue !== previousSearchValue.current) {
            reqBody = {
              filterText: searchValue.toLowerCase(),
              pageToDeliver: 1,
              ...defaultReqBody
            };
          } else {
            reqBody = {
              filterText: searchValue.toLowerCase(),
              pageToDeliver: currentPage,
              ...defaultReqBody
            };
          }
        } else {
          if (previousSearchValue.current !== '') {
            setIsSearchBusy(true);
          }
          reqBody = {
            pageToDeliver: currentPage,
            ...defaultReqBody
          };
        }
        const result = await postToApi('v1/OrderPosition/Paged', reqBody, abortController.signal);
        setMaxPages(result.pagesAvailable);
        setOrderPositions(result.results);
      } catch (error) {
        setMaxPages(1);
        setCurrentPage(1);
        setOrderPositions([]);
      } finally {
        previousSearchValue.current = searchValue;
        setIsBusy(false);
        setIsSearchBusy(false);
      }
    };
    fetchData();
  }, [currentPage, setCurrentPage, searchValue, setSearchValue, sortAscending, setSortAscending, sortPropertyName, setSortPropertyName]);

  /**
   * callback function for the download of one test result as pdf file.
   */
  const downloadTestResultAsPdf = async (orderPosition) => {
    setIsDownloadBusy(true);
    const date = toFormattedDate('de-DE', orderPosition.lastTestDateExecuted);
    const fileName = `Ergebnisse_${date}.pdf`;
    await postToApiBlob(`v1.0/Documents/result-document/de/${orderPosition.lastTestResultId}`, null, fileName, abortController.signal);
    setIsDownloadBusy(false);
  };

  /**
   * Updates the order positions table.
   * @param {*} newTestResult the new submitted test result.
   */
  const updateTableData = (newTestResult) => {
    orderPositions.forEach((orderPosition) => {
      if (orderPosition.id === newTestResult.orderPositionId) {
        orderPosition.lastTestResultId = newTestResult.id;
        orderPosition.lastTestDateExecuted = newTestResult.dateExecuted;
        orderPosition.wasLastTestSuccessful = newTestResult.isSuccessfulLeft === true && newTestResult.isSuccessfulRight === true;
      }
    });
    setOrderPositions([...orderPositions]);
  };

  /**
   * Toggles the manual test insertion dialog.
   */
  const toggleHideManualTestDialog = () => {
    setManualTestDialogOpen(!isManualTestDialogOpen);
  };

  /**
   * Click handler for activation / deactivation of order positions.
   * @param {*} target The targeted row in the orders table.
   */
  const toggleIsActive = async (target) => {
    setIsBusy(true);
    const idx = orderPositions.findIndex((x) => x.id === target.id && x.orderPositionId === target.orderPositionId);
    const reqBody = {
      id: orderPositions[idx].orderId,
      position: orderPositions[idx].position,
      isActive: !orderPositions[idx].isActive
    };
    try {
      const updatedOrderPosition = await postToApi('v1.0/Order/UpdateActive', reqBody);
      if (updatedOrderPosition) {
        orderPositions.forEach((orderPosition) => {
          if (orderPosition.id === target.id) {
            orderPosition.isActive = !orderPosition.isActive;
          }
        });
        setOrderPositions([...orderPositions]);
      }
    } catch (error) {
      console.error(error.text);
    } finally {
      setIsBusy(false);
    }
  };

  /**
   * toggle the visibility of the abort dialog
   */
  const toggleAbortDialog = () => {
    setAbortDialogOpen(!isAbortDialogOpen);
  };

  /**
   * Creates the context menu entries for the given order.
   * @param {*} orderPosition The target order position.
   */
  const menuItems = (orderPosition) => {
    const canDownload = orderPosition.lastTestResultId != null;
    const commonItems = [
      {
        key: 'details',
        text: I18n.get().t('ManualTestDialog_Details'),
        onClick: () => {
          setSelectedOrderPosition(orderPosition);
          setOrderDetailHidden(false);
        },
        iconProps: {
          iconName: 'user-alt-solid-svg',
          style: {
            color: theme.palette.neutralPrimary,
            fontSize: '13px'
          }
        }
      },
      {
        key: 'disable',
        text: orderPosition.isActive ? I18n.get().t('ManualTestDialog_Deactivate') : I18n.get().t('ManualTestDialog_Activate'),
        onClick: () => {
          setSelectedOrderPosition(orderPosition);
          toggleAbortDialog();
        },
        iconProps: {
          iconName: orderPosition.isActive ? 'disable-svg' : 'enable-solid-svg',
          style: {
            color: theme.palette.neutralPrimary,
            fontSize: '13px'
          }
        }
      }
    ];
    if (canDownload) {
      commonItems.push({
        key: 'download',
        text: I18n.get().t('ManualTestDialog_Download'),
        onClick: () => downloadTestResultAsPdf(orderPosition),
        iconProps: {
          iconName: 'download-solid-svg',
          style: {
            color: theme.palette.neutralPrimary,
            fontSize: '13px'
          }
        }
      });
    }
    if (isAccountManager || isUser) {
      commonItems.push({
        key: 'manualTest',
        text: I18n.get().t('ManualTestDialog_AddManualTest'),
        disabled: !orderPosition.isActive,
        onClick: () => {
          setSelectedOrderPosition(orderPosition);
          toggleHideManualTestDialog();
        },
        iconProps: {
          iconName: 'PageHeaderEdit',
          style: {
            color: theme.palette.neutralPrimary,
            fontSize: '13px'
          }
        }
      });
    }
    return commonItems;
  };

  /**
   * Sorts the table by the given property name considering the sort direction state.
   */
  const handlePropertySort = (propertyName) => {
    if (sortPropertyName === propertyName) {
      setSortAscending(!sortAscending);
    } else {
      setSortAscending(true);
      setSortPropertyName(propertyName);
    }
  };

  const columns = [
    {
      Header: '',
      accessor: 'interactions',
      width: 90,
      maxWidth: -1,
      minWidth: 90,
      Cell: (table) => {
        return (
          <ActionWrapper>
            {(isAccountManager || isUser) && (
              <StartButton
                disabled={!table.row.original.isActive}
                start={(event) => {
                  event.stopPropagation();
                  History.push({ pathname: '/test', wearer: table.row.original.wearer, orderPositionId: table.row.original.id });
                }}
              />
            )}
            <ContextMenu menuItems={menuItems(table.row.original)} />
          </ActionWrapper>
        );
      }
    },
    {
      Header: (
        <SortableTableHeader onClick={() => handlePropertySort('Wearer')}>
          {I18n.get().t('Table_Header_Supporter')}
          {sortPropertyName === 'Wearer' && <FontIcon iconName={sortAscending ? 'SortUp' : 'SortDown'} />}
        </SortableTableHeader>
      ),
      accessor: 'wearer',
      width: 110,
      Cell: (table) => {
        return table.row.original.isActive ? table.cell.value : <InactiveText>{table.cell.value}</InactiveText>;
      }
    },
    {
      Header: (
        <SortableTableHeader
          onClick={() => {
            hasSorted.current = true;
            handlePropertySort('OrderDeliveryInvoiceNumber');
          }}
        >
          {I18n.get().t('Table_Header_Number')}
          {hasSorted.current !== false && sortPropertyName === 'OrderDeliveryInvoiceNumber' && <FontIcon iconName={sortAscending ? 'SortUp' : 'SortDown'} />}
        </SortableTableHeader>
      ),
      accessor: 'orderDeliveryInvoiceNumber',
      width: 80,
      Cell: (table) => {
        return table.row.original.isActive ? (
          <Mono>{table.cell.value}</Mono>
        ) : (
          <InactiveText>
            <Mono>{table.cell.value}</Mono>
          </InactiveText>
        );
      }
    },
    {
      Header: (
        <SortableTableHeader onClick={() => handlePropertySort('OrderImportTimestamp')}>
          {I18n.get().t('Table_Header_Date')}
          {sortPropertyName === 'OrderImportTimestamp' && <FontIcon iconName={sortAscending ? 'SortUp' : 'SortDown'} />}
        </SortableTableHeader>
      ),
      accessor: 'orderImportTimestamp',
      width: 80,
      Cell: (table) => {
        const date = toFormattedDate('de-DE', table.cell.value);
        return table.row.original.isActive ? date : <InactiveText>{date}</InactiveText>;
      }
    },
    {
      Header: (
        <InfoWrapper onClick={() => handlePropertySort('DebtorIdentifier')}>
          {I18n.get().t('Table_Header_Debtor')}
          {sortPropertyName === 'DebtorIdentifier' && <FontIcon iconName={sortAscending ? 'SortUp' : 'SortDown'} />}
          <InfoButton
            id="debtor_ordersview"
            headlineText={I18n.get().t('Debtor_TeachingBubble_Headline')}
            teachingBubbleContent={I18n.get().t('Debtor_TeachingBubbleOrdersView_Text')}
          />
        </InfoWrapper>
      ),
      accessor: 'debtorIdentifier',
      width: 175,
      Cell: (table) => {
        return (
          <TooltipHost content={table.row.original.debtorCompanyName}>
            {table.row.original.isActive ? table.cell.value + ' ...' : <InactiveText>{table.cell.value} ...</InactiveText>}
          </TooltipHost>
        );
      }
    },
    {
      Header: (
        <SortableTableHeader onClick={() => handlePropertySort('Position')}>
          {I18n.get().t('Table_Header_Position')}
          {sortPropertyName === 'Position' && <FontIcon iconName={sortAscending ? 'SortUp' : 'SortDown'} />}
        </SortableTableHeader>
      ),
      accessor: 'position',
      width: 75,
      Cell: (table) => {
        return table.row.original.isActive ? (
          <Mono>{table.cell.value}</Mono>
        ) : (
          <InactiveText>
            <Mono>{table.cell.value}</Mono>
          </InactiveText>
        );
      }
    },
    {
      Header: (
        <SortableTableHeader onClick={() => handlePropertySort('OrderPositionSerialNumber')}>
          {I18n.get().t('Table_Header_Serial')}
          {sortPropertyName === 'OrderPositionSerialNumber' && <FontIcon iconName={sortAscending ? 'SortUp' : 'SortDown'} />}
        </SortableTableHeader>
      ),
      accessor: 'orderPositionSerialNumber',
      width: 85,
      Cell: (table) => {
        return table.row.original.isActive ? (
          <Mono>{table.cell.value}</Mono>
        ) : (
          <InactiveText>
            <Mono>{table.cell.value}</Mono>
          </InactiveText>
        );
      }
    },
    {
      Header: (
        <InfoWrapper onClick={() => handlePropertySort('WasLastTestSuccessful')}>
          {I18n.get().t('Table_Header_Status')}
          {sortPropertyName === 'WasLastTestSuccessful' && <FontIcon iconName={sortAscending ? 'SortUp' : 'SortDown'} />}
          <InfoButton id="test_status_resultsview" headlineText={I18n.get().t('InfoButton_Dialog_Headline')} teachingBubbleContent={TestStatusTeachingBubbleContent()} />
        </InfoWrapper>
      ),
      accessor: 'wasLastTestSuccessful',
      Cell: (table) => {
        return table.row.original.isActive ? (
          <StatusIcon status={table.cell.value} />
        ) : (
          <InactiveText>
            <StatusIcon status={table.cell.value} />
          </InactiveText>
        );
      }
    },
    {
      Header: (
        <SortableTableHeader onClick={() => handlePropertySort('LastTestDateExecuted')}>
          {I18n.get().t('Table_Header_LastResult')}
          {sortPropertyName === 'LastTestDateExecuted' && <FontIcon iconName={sortAscending ? 'SortUp' : 'SortDown'} />}
        </SortableTableHeader>
      ),
      accessor: 'lastTestDateExecuted',
      width: 135,
      Cell: (table) => {
        if (!table.cell.value) {
          return null;
        }
        const date = toFormattedDateTime('de-DE', table.cell.value);
        return table.row.original.isActive ? date : <InactiveText>{date}</InactiveText>;
      }
    }
  ];

  return (
    <OrderViewContainer>
      {selectedOrderPosition && (
        <BasicDialog
          hidden={!isAbortDialogOpen}
          title={I18n.get().t('AbortOrderStatusDialog_Headline')}
          text={selectedOrderPosition.isActive ? I18n.get().t('AbortOrderStatusDialog_Disable_Text') : I18n.get().t('AbortOrderStatusDialog_Activate_Text')}
          confirmCallback={() => {
            toggleIsActive(selectedOrderPosition);
            toggleAbortDialog();
          }}
          cancelCallback={toggleAbortDialog}
        />
      )}
      <TableView
        isBusy={isBusy}
        isBusyAlt={isDownloadBusy}
        data={orderPositions}
        columns={columns}
        viewName={I18n.get().t('ViewName_Orders')}
        maxPages={maxPages}
        currentPage={currentPage}
        setCurrentPage={(page) => setCurrentPage(page)}
        setSearchValue={(searchValue) => setSearchValue(searchValue)}
        isSearchBusy={isSearchBusy}
      />
      {selectedOrderPosition && <OrderDetailDialog hidden={orderDetailHidden} selectedOrderPosition={selectedOrderPosition} toggle={setOrderDetailHidden} />}
      {selectedOrderPosition && (
        <ManualTestDialog
          hidden={!isManualTestDialogOpen}
          name={selectedOrderPosition.wearer}
          orderPositionId={selectedOrderPosition.id}
          orderPositionNumber={selectedOrderPosition.position}
          toggleHideDialog={toggleHideManualTestDialog}
          updateTableData={updateTableData}
          resetParentIsBusy={() => setIsBusy(false)}
        />
      )}
    </OrderViewContainer>
  );
};
