import React from 'react';
import styled from 'styled-components';
import { Spinner, SpinnerSize } from '@fluentui/react';

const BusyOverlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.85);
    z-index: 1;
`;

/**
 * Basic component to indicate async operations.
 * NOTE: To use place this component inside a container that has set 'position: relative'. This component then will take 100% of that container space and displays itself as overlay.
 */
export const BusySpinnerOverlay = (props) => {
    return (
        <>
            {props.isBusy ? (
                <BusyOverlay>
                    <Spinner size={SpinnerSize.large} />
                </BusyOverlay>
            ) : null}
        </>
    );
};
