import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import Avatar from './Avatar';
import { UserMenu } from './UserMenu';
import { getTheme } from 'office-ui-fabric-react';
import { OfflineSwitch } from './OfflineSwitch';

const TopMenuContainer = styled.div`
    display: flex;
    position: fixed;
    flex-direction: row;
    background-color: ${(props) => props.theme.palette.themePrimary};
    justify-content: space-between;
    border-bottom: 1px solid ${(props) => props.theme.palette.themePrimary};
    width: 100%;
    z-index: 9996;
    height: var(--top-menu-height);
`;
const LogoContainer = styled.div`
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 16px;
    align-items: center;
`;

const OfflineSwitchWrapper = styled.div`
    margin-left: 20px;
`;

const Logo = styled.img`
    height: 50px;
`;

const UserMenuWrapper = styled.div`
    display: flex;
    position: relative;
    cursor: pointer;
`;

/** Top menu bar with logo and username. */
const TopMenu = () => {
    const theme = getTheme();
    /**
     * Whether the user menu is open or not.
     */
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

    /**
     * Callback for the click event listener.
     */
    const clickListener = useCallback(() => {
        if (isUserMenuOpen) {
            setIsUserMenuOpen(false);
        }
    }, [isUserMenuOpen]);

    /**
     * Add an event listener to close the user menu.
     */
    useEffect(() => {
        // Attach the listeners on component mount.
        document.addEventListener('click', clickListener);
        // Detach the listeners on component unmount.
        return () => {
            document.removeEventListener('click', clickListener);
        };
    }, [clickListener]);
    return (
        <TopMenuContainer theme={theme}>
            <LogoContainer>
                <Logo src="logo.png"></Logo>
                <OfflineSwitchWrapper>
                    <OfflineSwitch />
                </OfflineSwitchWrapper>
            </LogoContainer>
            <UserMenuWrapper onClick={() => setIsUserMenuOpen(true)}>
                <Avatar />
                <UserMenu isVisible={isUserMenuOpen} />
            </UserMenuWrapper>
        </TopMenuContainer>
    );
};

export default TopMenu;
