import { createStore, action } from 'easy-peasy';

/**
 * Holds relevant user data and actions to modify that data.
 */
const userModel = {
    /**
     * Roles assigned to the user, used for authorization
     */
    roles: [],
    /**
     * Adds a role to the user
     */
    addRole: action((state, payload) => {
        state.roles.push(payload);
    }),
};

/**
 * State for ui elements and actions to alter that state.
 */
const uiModel = {
    /**
     * Whether or not the nav menu is open.
     */
    menuOpen: true,
    /**
     * Action to set menuOpen state.
     */
    toggleMenu: action((state, payload) => {
        state.menuOpen = payload;
    }),
    /**
     * Used by the fluent ui nav to indicate which element should be highlighted.
     */
    currentNavKey: '/users',
    /**
     * Changes the highlighted nav element.
     */
    changeCurrentNavKey: action((state, payload) => {
        state.currentNavKey = payload;
    }),
    /**
     * List of notifications which need to be displayed to the user.
     */
    notifications: [],
    /**
     * Adds a notification to the list.
     */
    addNotification: action((state, payload) => {
        state.notifications.push(payload);
    }),
    /**
     * Whether the offline mode is enabled or not.
     */
    isOfflineModeEnabled: false,
    /**
     * Action to set offline mode state.
     */
     updateIsOfflineModeEnabled: action((state, payload) => {
        state.isOfflineModeEnabled = payload;
    }),
};

/**
 * Holds common data that is used across the whole site.
 */
const dataModel = {
    /**
     * List of available debtors.
     */
    debtors: null,
    /**
     * Action to alter the debtor list.
     */
    changeDebtors: action((state, payload) => {
        state.debtors = payload;
    }),
};

const storeModel = {
    user: userModel,
    ui: uiModel,
    data: dataModel,
};

const Store = createStore(storeModel);

export default Store;
