import * as React from 'react';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';

const BusyIndicator = (props) => {
    const progressIndicatorStyles = {
        root: {
            zIndex: 10,
        },
        itemProgress: {
            paddingTop: 0,
        },
    };

    return props.isVisible ? <ProgressIndicator styles={progressIndicatorStyles} /> : null;
};

export default BusyIndicator;