import React, { useMemo, useState } from 'react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import DebtorSearch from './DebtorSearch';
import DebtorList from './DebtorList';
import styled from 'styled-components';
import I18n from '../../../helper/Localization';
import { postToApi } from '../../../helper/ApiHelper';
import { BasicDialog } from '../BasicDialog';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { Colors } from '../../../styles/Globals';
import { BusySpinner } from '../../busyIndicators/BusySpinner';
import { validateEmail } from '../../../helper/ValidationHelper';
import { OpenEndCustomerDialogButton } from '../endCustomerDialog/OpenEndCustomerDialogButton';
import { EndCustomerDialog } from '../endCustomerDialog/EndCustomerDialog';

/**
 * styled components
 */
const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 300px;
    justify-content: space-between;
`;

const BottomContainer = styled.div`
    margin-top: auto;
    display: flex;
    flex: 1;
    overflow: hidden;
    height: calc(100% - 335px);
`;

const UserInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-right: 30px;
`;

const InputElement = styled.div`
    height: 50px;
`;

const FlexRow = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;

const UserInput = styled.div`
    border: 1px solid var(--unnamed-color-f0f0f0);
    padding: 20px 20px 0px 20px;
    background-color: ${Colors.backgroundGray};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin: 0px;
`;

const UserInputHeadline = styled.p`
    color: var(--unnamed-color-000000);
    text-align: left;
    margin-bottom: 10px;
    font: Bold 12px/15px NHaasGroteskTXW01-75Bd;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
`;

const PanelBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    height: calc(100vh - 110px);
    @media only screen and (max-width: 1400px) {
        height: calc(100vh - 135px);
    }
`;

const PanelFooter = styled.div`
    display: flex;
    padding: 10px 20px;
`;

const EndCustomerButtonWrapper = styled.div`
    display: flex;
    margin-bottom: auto;
    padding: 15px 0;
`;

/**
 * The dialog to invite users
 */
const InviteUserDialog = (props) => {
    /**
     * Disabled state of the submit button.
     */
    const [submitDisabled, setSubmitDisabled] = useState(false);

    /**
     * User notification message for the MessageBar.
     */
    const [userMessage, setUserMessage] = useState('');

    /**
     * visible state for the busy indicator
     */
    const [isBusy, setIsBusy] = useState(false);

    /**
     * A list of all currently assigned debtors.
     */
    const [assignedDebtors, setAssignedDebtors] = useState([]);

    /**
     * Email property directly wired to the email input.
     */
    const [email, setEmail] = useState('');

    /**
     * CompanyName property directly wired to the CompanyName input.
     */
    const [companyName, setCompanyName] = useState('');

    /**
     * Department property directly wired to the Department input.
     */
    const [department, setDepartment] = useState('');

    /**
     * Used by fluent ui for different message bar themes based on this type.
     */
    const [userMessageType, setUserMessageType] = useState(MessageBarType.info);

    /**
     * Whether the abort dialog is open or not.
     */
    const [isAbortDialogOpen, setIsAbortDialogOpen] = useState(false);

    /**
     * Whether the dialog to edit end customer numbers is open or not.
     */
    const [isEndCustomerDialogOpen, setIsEndCustomerDialogOpen] = useState(false);

    /**
     * State of the current assigned end customer numbers for the user.
     */
    const [endCustomerNumbers, setEndCustomerNumbers] = useState([]);

    /**
     * fluent ui styles and properties
     */
    const inputStyles = {
        root: {
            width: '100%',
            fontSize: 10,
        },
        field: {
            height: 23,
            paddingLeft: 20,
        },
        fieldGroup: {
            height: 24,
            border: `0.5px solid ${Colors.borderGray};`,
        },
        errorMessage: {
            fontSize: 10,
        },
    };

    const messageBarStyles = {
        root: {
            width: '100%',
        },
        icon: {
            color: Colors.errorRed,
        },
    };

    const cancleButtonStyles = {
        root: {
            border: 'unset',
            fontSize: '10px',
            marginLeft: '15px',
        },
    };

    const inviteButtonStyles = {
        root: {
            fontSize: '10px',
            marginLeft: '15px',
        },
    };

    /**
     * Reset the states and close the dialog.
     */
    const closePanel = () => {
        setIsAbortDialogOpen(false);
        setSubmitDisabled(false);
        setUserMessage('');
        setIsBusy(false);
        setAssignedDebtors([]);
        setEmail('');
        setCompanyName('');
        setDepartment('');
        setUserMessageType(MessageBarType.info);
        setEndCustomerNumbers([]);
        props.toggleHideDialog();
    };

    /**
     * Open the abort dialog when the input states are not default. If abort dialog must not be open, trigger close.
     */
    const openAbortDialog = () => {
        if (userMessage.length !== 0 || assignedDebtors.length !== 0 || email.length !== 0 || companyName.length !== 0 || department.length !== 0) {
            setIsAbortDialogOpen(true);
        } else {
            closePanel();
        }
    };

    /**
     * Handles the deselection of a debtor in the assigned debtors list.
     * @param {object} target the targeted debtor.
     */
    const deselectDebtor = (target) => {
        const updatedSelection = assignedDebtors.filter((debtor) => debtor.id !== target.id);
        setAssignedDebtors(updatedSelection);
    };

    /**
     * Handles the selection of a debtor in the debtors list.
     * @param {object} target The targeted debtor.
     */
    const selectDebtor = (target) => {
        setAssignedDebtors([...assignedDebtors, target]);
    };

    /**
     * Form submit.
     */
    const submit = async () => {
        if (!validateForm()) {
            return;
        }
        const reqBody = {
            company: companyName,
            debtorIds: assignedDebtors.length > 0 ? assignedDebtors.map((debtor) => debtor.id) : [],
            endCustomerNumbers: endCustomerNumbers,
            department: department,
            email: email,
            isActive: true,
            lastLogin: new Date().toISOString(),
            id: 0,
        };
        try {
            setSubmitDisabled(true);
            setIsBusy(true);
            await postToApi('v1.0/User', reqBody);
            setUserMessageType(MessageBarType.success);
            setUserMessage(I18n.get().t('Invite_User_SuccessMessage'));
            setTimeout(() => closePanel(), 2000);
        } catch (error) {
            if (error.status === 409) {
                setUserMessage(I18n.get().t('Invite_User_EmailExistsError'));
                setUserMessageType(MessageBarType.error);
            } else {
                setUserMessage(I18n.get().t('Invite_User_Error'));
                setUserMessageType(MessageBarType.error);
                console.error(error.text);
            }
        } finally {
            setSubmitDisabled(false);
            setIsBusy(false);
        }
    };

    /**
     * Validates if the input fields contain valid data.
     */
    const validateForm = () => {
        setUserMessage('');
        if (!validateEmail(email)) {
            setUserMessage(I18n.get().t('Invite_User_InsertEmail'));
            setUserMessageType(MessageBarType.error);
            return false;
        }
        if (validateInputNotEmpty(companyName) !== '') {
            setUserMessage(I18n.get().t('Invite_User_InsertCompany'));
            setUserMessageType(MessageBarType.error);
            return false;
        }
        return true;
    };

    /**
     * Regex email validation.
     * Returns a error text to fluent ui.
     * @param value The data that requires validation.
     */
    const emailOnGetError = (value) => {
        return validateEmail(value) ? '' : I18n.get().t('Invite_User_InvalidEmail');
    };

    /**
     * Validates whether or not a input is empty.
     * Returns a error text to fluent ui.
     * @param value The data that requires validation
     */
    const validateInputNotEmpty = (value) => {
        return value === '' ? I18n.get().t('Invite_User_InvalidInput') : '';
    };

    /**
     * In memory list of the assigned debtors.
     */
    const inMemoryAssignedDedbtorList = useMemo(
        () => <DebtorList assignedDebtors={assignedDebtors} deselectDebtor={deselectDebtor} />,
        [assignedDebtors, setAssignedDebtors, deselectDebtor]
    );

    /**
     * Callback to close the end customer panel.
     */
    const closeEndCustomerPanel = () => {
        setIsEndCustomerDialogOpen(false);
    };

    /**
     * In memory list of all possible debtors.
     */
    const inMemorySearchableDebtorsList = useMemo(() => <DebtorSearch assignedDebtors={assignedDebtors} selectDebtor={selectDebtor} />, [assignedDebtors,selectDebtor]);

    /**
     * Body of the article timeline panel
     */
    const onRenderBody = () => (
        <PanelBody>
            <TopContainer>
                <UserInputContainer>
                    <UserInputHeadline>{I18n.get().t('Invite_User_Input_Title')}</UserInputHeadline>
                    <UserInput>
                        <InputElement>
                            <TextField
                                className="email"
                                styles={inputStyles}
                                defaultValue={email}
                                placeholder={I18n.get().t('Invite_User_EmailInput_Placeholder')}
                                validateOnFocusOut
                                validateOnLoad={false}
                                onGetErrorMessage={emailOnGetError}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </InputElement>
                        <InputElement>
                            <TextField
                                styles={inputStyles}
                                defaultValue={companyName}
                                placeholder={I18n.get().t('Invite_User_CompanyInput_Placeholder')}
                                validateOnFocusOut
                                validateOnLoad={false}
                                onGetErrorMessage={validateInputNotEmpty}
                                onChange={(e) => setCompanyName(e.target.value)}
                            />
                        </InputElement>
                        <InputElement>
                            <TextField
                                styles={inputStyles}
                                defaultValue={department}
                                placeholder={I18n.get().t('Invite_User_DepartmentInput_Placeholder')}
                                onChange={(e) => setDepartment(e.target.value)}
                            />
                        </InputElement>
                    </UserInput>
                    <EndCustomerButtonWrapper>
                        <OpenEndCustomerDialogButton callback={() => setIsEndCustomerDialogOpen(true)} />
                    </EndCustomerButtonWrapper>
                </UserInputContainer>
                {inMemoryAssignedDedbtorList}
            </TopContainer>
            <BottomContainer>{inMemorySearchableDebtorsList}</BottomContainer>
        </PanelBody>
    );

    /**
     * Footer content of the panel.
     */
    const onRenderFooterContent = () => (
        <PanelFooter>
            {userMessage !== '' && (
                <MessageBar styles={userMessageType === MessageBarType.error && messageBarStyles} isMultiline={false} messageBarType={userMessageType}>
                    {userMessage}
                </MessageBar>
            )}

            <FlexRow>
                <BusySpinner isBusy={isBusy} />
                <DefaultButton styles={cancleButtonStyles} onClick={openAbortDialog} text={I18n.get().t('Invite_User_Cancle_Label')} />
                <PrimaryButton styles={inviteButtonStyles} disabled={submitDisabled} onClick={submit} text={I18n.get().t('Invite_User_Submit_Label')} />
            </FlexRow>
        </PanelFooter>
    );

    return (
        <>
            <EndCustomerDialog
                isOpen={isEndCustomerDialogOpen}
                closeCallback={closeEndCustomerPanel}
                endCustomerNumbers={endCustomerNumbers}
                setEndCustomerNumbers={setEndCustomerNumbers}
            />
            <Panel
                allowTouchBodyScroll
                headerText="Neuen Benutzer einladen"
                isOpen={props.isOpen && !isEndCustomerDialogOpen}
                isFooterAtBottom={true}
                onDismiss={openAbortDialog}
                type={PanelType.extraLarge}
                closeButtonAriaLabel="Schließen"
                onRenderBody={onRenderBody}
                onRenderFooter={onRenderFooterContent}
                onOuterClick={() => {
                    // NOTE: This is needed to prevent the panel from closing when the user interacts with the dialog.
                }}
            />
            <BasicDialog
                hidden={!isAbortDialogOpen}
                title={I18n.get().t('AbortUserInvitationDialog_Headline')}
                text={I18n.get().t('AbortUserInvitationDialog_Text')}
                confirmCallback={closePanel}
                cancelCallback={() => setIsAbortDialogOpen(false)}
            />
        </>
    );
};

export default InviteUserDialog;
