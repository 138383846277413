import React, { Component } from 'react';
import styled from 'styled-components';
import I18n from '../../helper/Localization';
import { TestInstructionCard } from './TestInstructionCard';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react';

const InstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 55px 0 73px 51px;
  width: 70%;
  font-size: 40px;
  font-weight: bold;
  color: #000000;
  align-self: flex-start;
  @media only screen and (max-width: 1400px) {
   font-size: 23px; 
  }
`;

const InstructionCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 93%;
  height: 40%;
  justify-content: space-around;
  margin-top: auto;
  margin-bottom: auto;
`;

const ButtonContainer = styled.div`
  padding: 35px;
  width: 90%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`;

const continueButtonStyle = {
  root: {
    borderRadius: '5px',
    fontSize: '15px',
    border: 0,
    minWidth: '185px',
    height: '39px'
  },
  rootHovered: {
    backgroundColor: 'none',
    color: 'none'
  },
  rootPressed: {
    backgroundColor: 'none',
    color: 'none'
  }
};

const abortButtonStyle = {
  root: {
    border: 0,
    fontSize: '15px',
    minWidth: '179px',
    height: '39px'
  },
  rootHovered: {
    backgroundColor: 'none',
    color: 'none'
  },
  rootPressed: {
    backgroundColor: 'none',
    color: 'none'
  }
};

/**
 * view component that contains the instructions to be followed before starting the test
 */
export class TestInstructionsView extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <InstructionsContainer>
        <HeadlineContainer>{I18n.get().t('Test_Instructions_Header')}</HeadlineContainer>
        <InstructionCardsContainer>
          <TestInstructionCard number={1} text={I18n.get().t('Test_Instructions_One')} />
          <TestInstructionCard number={2} text={I18n.get().t('Test_Instructions_Two')} />
          <TestInstructionCard number={3} text={I18n.get().t('Test_Instructions_Three')} />
        </InstructionCardsContainer>
        <ButtonContainer>
          <DefaultButton styles={abortButtonStyle} text={I18n.get().t('Test_Instructions_Abort')} onClick={this.props.abort} />
          <PrimaryButton styles={continueButtonStyle} text={I18n.get().t('Test_Instructions_Continue')} onClick={this.props.continue} />
        </ButtonContainer>
      </InstructionsContainer>
    );
  }
}
