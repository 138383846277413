import React from 'react';
import { StatusIcon } from './StatusIcon';
import styled from 'styled-components';
import I18n from '../helper/Localization';

const TeachingBubbleContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const TeachingBubbleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
`;

const StatusIconWrapper = styled.div`
  margin-right: 15px;
`;

/**
 * Content of the teaching bubble that explains the test status icons.
 */
export const TestStatusTeachingBubbleContent = () => {
  return (
    <TeachingBubbleContent>
      <TeachingBubbleRow>
        <StatusIconWrapper>
          <StatusIcon status={undefined} />
        </StatusIconWrapper>
        {I18n.get().t('InfoButton_Dialog_Open')}
      </TeachingBubbleRow>
      <TeachingBubbleRow>
        <StatusIconWrapper>
          <StatusIcon status={true} />
        </StatusIconWrapper>
        {I18n.get().t('InfoButton_Dialog_Success')}
      </TeachingBubbleRow>
      <TeachingBubbleRow>
        <StatusIconWrapper>
          <StatusIcon status={false} />
        </StatusIconWrapper>
        {I18n.get().t('InfoButton_Dialog_Error')}
      </TeachingBubbleRow>
    </TeachingBubbleContent>
  );
};
