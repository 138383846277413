import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { LayoutRoute } from './components/LayoutRoute';
import History from './components/BrowserHistory';
import { RefreshRoute } from './components/RefreshRoute';
import { OrdersView } from './components/views/OrdersView';
import { UsersView } from './components/views/UsersView';
import { ResultsView } from './components/views/ResultsView';
import { OnlineHearingTest } from './components/hearingTest/OnlineHearingTest';
import RoleAuthorization from './components/RoleAuthorization';
import Roles from './types/roles';

// Restricts the access to administrators or account managers.
const AdministratorOrAccountManager = (component) => RoleAuthorization([Roles.Administrator, Roles.AccountManager])(component);

export const routes = (
    <Router history={History}>
        <Switch>
            <LayoutRoute exact path="/orders" component={OrdersView} />
            <LayoutRoute exact path="/results" component={ResultsView} />
            <LayoutRoute exact path="/users" component={AdministratorOrAccountManager(UsersView)} />
            <Route exact path="/test" component={OnlineHearingTest} />
            <RefreshRoute exact={false} path="/refresh" />
            <Redirect to="/" />
        </Switch>
    </Router>
);
