import React from 'react';
import { useStoreState } from 'easy-peasy';

const RoleAuthorization = (requiredRole) => (WrappedComponent) => {
  const WithAuthorizationWrapper = (props) => {
    const userRoles = useStoreState((state) => state.user.roles);
    if (userRoles.map((r) => r.role.label).some(r => requiredRole.includes(r))) {
      return <WrappedComponent {...props} />;
    }
    console.error(`Unauthorized, "${requiredRole}" is required.`);
    return <></>;
  };

  return WithAuthorizationWrapper;
};

export default RoleAuthorization;
