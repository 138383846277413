import React from 'react';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';
import { useConstCallback } from '@uifabric/react-hooks';
import { IconButton } from 'office-ui-fabric-react';

const buttonStyles = {
  icon: {
    marginBottom: '5px'
  },
  root: {
    height: '23px',
    width: '23px'
  },
  rootHovered: {
    backgroundColor: 'none',
  },
  rootPressed: {
    backgroundColor: 'none',
  },
  rootFocused: {
    outline: 0
  }
};

const contextStyles = {
  root: {
    minWidth: '200px',
    width: '200px'
  },
  beak: {
    visibility: 'hidden'
  }
};

/**
 * a basic context menu opened by a icon button that takes its items as prop
 * @param props 
 */
export const ContextMenu = (props) => {
  const linkRef = React.useRef(null);
  const [showContextualMenu, setShowContextualMenu] = React.useState(false);
  const onShowContextualMenu = useConstCallback(() => setShowContextualMenu(true));
  const onHideContextualMenu = useConstCallback(() => setShowContextualMenu(false));

  return (
    <div>
      <IconButton styles={buttonStyles} iconProps={{ iconName: 'ellipsis-v-solid-svg' }} onClick={onShowContextualMenu}>
        <a ref={linkRef} />
      </IconButton>
      <ContextualMenu
        isBeakVisible={false}
        styles={contextStyles}
        items={props.menuItems}
        hidden={!showContextualMenu}
        target={linkRef}
        onItemClick={onHideContextualMenu}
        onDismiss={onHideContextualMenu}
      />
    </div>
  );
};
