import React, { useEffect, useMemo, useState } from 'react';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { getTheme } from 'office-ui-fabric-react';
import styled from 'styled-components';
import I18n from '../../../helper/Localization';
import OrderTestList from './OrderTestList';
import { fetchApiObject } from '../../../helper/ApiHelper';
import { Colors } from '../../../styles/Globals';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';

const Label = styled.div`
  text-align: left;
  margin-bottom: 5px;
  font: Bold 10px/15px NHaasGroteskTXW01-75Bd;
  letter-spacing: 0px;
  color: ${Colors.inactiveGray};
  opacity: 1;
`;

const OrderTestListWrapper = styled.div`
  display: flex;
  overflow: hidden;
`;

const Data = styled.div`
  text-align: left;
  margin-bottom: 10px;
  font: 400 14px/15px NeueHaasGroteskText W01;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;

const UserDataContainer = styled.div`
  margin-bottom: 30px;
`;

const LabelContainer = styled.div`
  margin-right: 10px;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserData = styled.div`
  border: 1px solid var(--unnamed-color-f0f0f0);
  padding: 20px 20px 10px 20px;
  background-color: ${Colors.backgroundGray};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  height: fit-content;
  margin: 0px;
`;

const Text = styled.p`
  color: var(--unnamed-color-000000);
  text-align: left;
  margin-bottom: 10px;
  font: Bold 12px/15px NHaasGroteskTXW01-75Bd;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;

const PanelBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  height: calc(100vh - 130px);
  overflow-y: hidden;
  @media only screen and (max-width: 1400px) {
    height: calc(100vh - 155px);
  }
`;

const PanelFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
`;

/**
 * Dialog displaying detailed information about an order.
 */
const OrderDetailDialog = (props) => {
  /**
   * visible state for the busy indicator
   */
  const [isBusy, setIsBusy] = useState(false);

  /**
   * State of the test results that belkong to the order position.
   */
  const [testResults, setTestResults] = useState([]);

  useEffect(() => {
    if (props.selectedOrderPosition && props.hidden === false) {
      const fetchData = async () => {
        const result = await fetchApiObject(`v1/TestResult/OrderPosition/${props.selectedOrderPosition.id}`);
        setTestResults(result);
      };
      fetchData();
    }
  }, [props.selectedOrderPosition, props.hidden]);

  const cancleButtonStyles = {
    root: {
      fontSize: '10px'
    }
  };

  /**
   * Close the dialog.
   */
  const closeDialog = () => {
    setTestResults([]);
    props.toggle();
  };

  /**
   * Body of the panel
   */
  const onRenderBody = () => (
    <PanelBody>
      <UserDataContainer>
        <Text>{I18n.get().t('OrderDetail_UserData_Title')}</Text>
        <UserData>
          <LabelContainer>
            <Label>{`${I18n.get().t('OrderDetail_UserData_Ordernumber_Label')}:`}</Label>
            <DataContainer>
              <Data>{props.selectedOrderPosition.orderDeliveryInvoiceNumber}</Data>
            </DataContainer>
            <Label>{`${I18n.get().t('OrderDetail_UserData_Position_Label')}:`}</Label>
            <DataContainer>
              <Data>{props.selectedOrderPosition.position}</Data>
            </DataContainer>
            <Label>{`${I18n.get().t('OrderDetail_UserData_Wearer_Label')}:`}</Label>
            <DataContainer>
              <Data>{props.selectedOrderPosition.wearer}</Data>
            </DataContainer>
          </LabelContainer>
        </UserData>
      </UserDataContainer>
      <OrderTestListWrapper>
        <OrderTestList setBusy={setIsBusy} testResults={testResults} />
      </OrderTestListWrapper>
    </PanelBody>
  );

  /**
   * Footer content of the panel.
   */
  const onRenderFooterContent = () => (
    <PanelFooter>
      <PrimaryButton styles={cancleButtonStyles} onClick={closeDialog} text={I18n.get().t('OrderDetail_Close_Button')} />
    </PanelFooter>
  );

  return (
    <Panel
      allowTouchBodyScroll
      isLightDismiss
      isOpen={props.hidden === false}
      headerText={I18n.get().t('OrderDetail_Title')}
      isFooterAtBottom={true}
      onDismiss={closeDialog}
      type={PanelType.extraLarge}
      closeButtonAriaLabel="Schließen"
      onRenderBody={onRenderBody}
      onRenderFooter={onRenderFooterContent}
    />
  );
};

export default OrderDetailDialog;
