import React, { useState, useEffect, useCallback } from 'react';
import TestLayout from './TestLayout';
import History from '../BrowserHistory';
import I18n from '../../helper/Localization';
import { OfflineIntroView } from './OfflineIntroView';
import { TestIntroView } from './TestIntroView';
import { TestInstructionsView } from './TestInstructionsView';
import { TestExecutionView } from './TestExecutionView';
import { TestSuccessView } from './TestSuccessView';
import { TestFailedView } from './TestFailedView';
import { BasicDialog } from '../dialogs/BasicDialog';

/**
 * component that handles the complete hearing test
 */
export const HearingTest = (props) => {
  const minimumInsulationPerEar = 12;

  /**
   * Whether the abort dialog is open or not.
   */
  const [isAbortDialogOpen, setIsAbortDialogOpen] = useState(false);

  /**
   * State of the current test progress.
   */
  const [progress, setProgress] = useState(0);

  /**
   * State of the left db value without protection.
   */
  const [dbLeftWithoutProtection, setDbLeftWithoutProtection] = useState(-1);

  /**
   * State of the right db value without protection.
   */
  const [dbRightWithoutProtection, setDbRightWithoutProtection] = useState(-1);

  /**
   * State of the left db value with protection.
   */
  const [dbLeftWithProtection, setDbLeftWithProtection] = useState(-1);

  /**
   * State of the right db value with protection.
   */
  const [dbRightWithProtection, setDbRightWithProtection] = useState(-1);

  /**
   * Whether the hearing test is busy or not.
   */
  const [isBusy, setIsBusy] = useState(false);

  /**
   * continues the test progress
   */
  const progressHandler = useCallback(() => {
    setProgress(Math.min(5, progress + 1));
  }, [progress]);

  useEffect(() => {
    if(props.startProgress) {
        setProgress(props.startProgress);
    }
  }, [props.startProgress])

  /**
   * Finish test after last test step is finished.
   */
  useEffect(() => {
    if (dbRightWithProtection !== -1 && progress === 3) {
      try {
        setIsBusy(true);
        props.finishAndSaveTest(
          dbLeftWithoutProtection,
          dbLeftWithProtection,
          dbRightWithoutProtection,
          dbRightWithProtection,
          calcInsulation(dbLeftWithoutProtection, dbLeftWithProtection),
          calcInsulation(dbRightWithoutProtection, dbRightWithProtection),
          calcInsulation(dbLeftWithoutProtection, dbLeftWithProtection) >= minimumInsulationPerEar,
          calcInsulation(dbRightWithoutProtection, dbRightWithProtection) >= minimumInsulationPerEar
        );
        progressHandler();
      } catch (error) {
        console.error(error);
      } finally {
        setIsBusy(false);
      }
    }
  }, [dbLeftWithProtection, dbLeftWithoutProtection, dbRightWithProtection, dbRightWithoutProtection, progress, progressHandler, props, setDbRightWithProtection]);

  /**
   * restarts the test for the same user with the same order position
   */
  const restartHandler = () => {
    if (props.isOfflineModeEnabled) {
      setProgress(-1);
    } else {
      setProgress(0);
    }
    setDbLeftWithoutProtection(-1);
    setDbRightWithoutProtection(-1);
    setDbLeftWithProtection(-1);
    setDbRightWithProtection(-1);
    if (props.updateOfflineNotes) {
      props.updateOfflineNotes('');
    }
  };

  /**
   * go back in the test progress
   */
  const progressHandlerBackwards = () => {
    setProgress(Math.min(5, progress - 1));
  };

  /**
   * sets the db state for the ear site with and without hearing protection based on params
   * @param site the ear site
   * @param withProtection if the test was with or without hearing protection
   * @param db the calculated decibel value
   */
  const dbHandler = (site, withProtection, db, callback) => {
    if (site === 'left' && !withProtection) {
      setDbLeftWithoutProtection(db);
      callback();
    } else if (site === 'right' && !withProtection) {
      setDbRightWithoutProtection(db);
      callback();
    } else if (site === 'left' && withProtection) {
      setDbLeftWithProtection(db);
      callback();
    } else if (site === 'right' && withProtection) {
      setDbRightWithProtection(db);
    }
  };

  /**
   * toggle the visibility of the abort dialog
   */
  const toggleAbortDialog = () => {
    setIsAbortDialogOpen(!isAbortDialogOpen);
  };

  /**
   * aborts the current test
   */
  const abortHandler = () => {
    setDbLeftWithoutProtection(-1);
    setDbRightWithoutProtection(-1);
    setDbLeftWithProtection(-1);
    setDbRightWithProtection(-1);
    if (props.isOfflineModeEnabled) {
      setProgress(-1);
      if (props.updateOfflineNotes) {
        props.updateOfflineNotes('');
      }
      setIsAbortDialogOpen(false);
    } else {
      History.goBack();
    }
  };

  /**
   * calculates the test result db
   */
  const calcInsulation = (dbWithoutProtection, dbWithProtection) => {
    return Math.round(20 * Math.log10(dbWithProtection / dbWithoutProtection));
  };

  /**
   * helper method that returns the correct test view
   * @param param
   */
  const renderSwitch = (param) => {
    switch (param) {
      case -1:
        return (
          <OfflineIntroView
            continue={progressHandler}
            userMail={props.executorEmail && props.executorEmail}
            notes={props.offlineNotes && props.offlineNotes}
            updateEmail={(email) => props.updateExecutorMail(email)}
            updateNotes={(notes) => props.updateOfflineNotes(notes)}
          />
        );
      case 0:
        return <TestIntroView wearerName={props.wearerName && props.wearerName} continue={progressHandler} abort={toggleAbortDialog} />;
      case 1:
        return <TestInstructionsView continue={progressHandler} abort={toggleAbortDialog} />;
      case 2:
        return <TestExecutionView continue={progressHandler} abort={toggleAbortDialog} dbHandler={dbHandler} />;
      case 3:
        return <TestExecutionView continue={progressHandler} back={progressHandlerBackwards} abort={toggleAbortDialog} dbHandler={dbHandler} />;
      case 4:
        return calcInsulation(dbLeftWithoutProtection, dbLeftWithProtection) >= minimumInsulationPerEar &&
          calcInsulation(dbRightWithoutProtection, dbRightWithProtection) >= minimumInsulationPerEar ? (
          <TestSuccessView abort={abortHandler} />
        ) : (
          <TestFailedView abort={abortHandler} restart={restartHandler} />
        );
      default:
        return <div />;
    }
  };

  return (
    <TestLayout progress={progress} isBusy={isBusy}>
      <BasicDialog
        hidden={!isAbortDialogOpen}
        title={I18n.get().t('AbortTestDialog_Headline')}
        text={I18n.get().t('AbortTestDialog_Text')}
        confirmCallback={abortHandler}
        cancelCallback={toggleAbortDialog}
      />
      {renderSwitch(progress)}
    </TestLayout>
  );
};
