import { UserAgentApplication } from 'msal';
import { _config } from '../OnlineApp';

/**
 * Determines from the error message whether a user interaction is required.
 * @param {string} errorMessage The text of the current error message
 */
export const requiresInteraction = (errorMessage) => {
    if (!errorMessage || !errorMessage.length) {
        return false;
    }
    return errorMessage.indexOf('consent_required') > -1 || errorMessage.indexOf('interaction_required') > -1 || errorMessage.indexOf('login_required') > -1;
};

/**
 * Determines whether the current browser is an Internet Explorer.
 */
export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ') > -1;
    const msie11 = ua.indexOf('Trident/') > -1;
    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    const isEdge = ua.indexOf('Edge/') > -1;
    return msie || msie11 || isEdge;
};

/**
 * Retrieves an authentication token.
 * @param {string[]} scopes The scopes to request a token for
 */
export const getToken = async (scopes) => {
    const app = await msalApp();
    const azureAdOptions = (await _config).azureAdOptions;
    const request = { scopes: scopes, redirectUri: window.location.origin + azureAdOptions.callbackPath };
    const authResponse = await app.acquireTokenSilent(request).catch((error) => {
        if (requiresInteraction(error.errorCode)) {
            return app.acquireTokenRedirect(request);
        }
    });
    return authResponse ? authResponse.accessToken : null;
};

/**
 * Primary Sign-In method for authorization.
 * Returns true, if the user is signed in, false otherwise.
 */
export const signIn = async () => {
    const app = await msalApp();
    if (app.redirectError && app.redirectError.errorMessage.indexOf('AADB2C90118') > -1) {
        // App is redirecting due to forgotten password.
        return false;
    }
    if (app.getAccount()) {
        // User is already signed in.
        return true;
    }
    await login();
    // The app will redirect to sign-in, thus the user is not yet signed in.
    return false;
};

/**
 * Sign-out the user
 */
export const logout = async () => {
    const app = await msalApp();
    localStorage.removeItem('lastLoggedInUserMail');
    // Removes all sessions, need to call AAD endpoint to do full logout
    app.logout(app.getAccount());
};

/**
 * Internal singleton MSAL-client
 */
let _app = undefined;

/**
 * Authentication handler for login and acquiring tokens.
 */
export const msalApp = async () => {
    if (!_app) {
        // initialize the MSAL-client
        const azureAdOptions = (await _config).azureAdOptions;
        const b2COptions = (await _config).b2COptions;
        const flow = window.location.pathname.includes('signup') ? b2COptions.signUp : b2COptions.signUpAndSignIn;
        _app = new UserAgentApplication({
            auth: {
                clientId: azureAdOptions.clientId,
                authority: `${azureAdOptions.instance}/tfp/${azureAdOptions.domain}/${flow}`,
                validateAuthority: false,
                redirectUri: window.location.origin + azureAdOptions.callbackPath,
                navigateToLoginRequestUrl: true,
                postLogoutRedirectUri: window.location.origin,
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: isIE(),
            },
        });
        _app.handleRedirectCallback(authRedirectCallBack);
    }
    return _app;
};

/**
 * Login request against the configured authority.
 */
const login = async () => {
    const b2COptions = (await _config).b2COptions;
    const loginRequest = {
        scopes: b2COptions.Scopes,
    };
    await _app.loginRedirect(loginRequest);
};

/**
 * Authorization redirect callback. This is called after the user has interacted with the login dialog.
 * @param {*} error An error has occurred and must be handled.
 * @param {*} response The response containing tokens.
 */
const authRedirectCallBack = async (error, response) => {
    const b2COptions = (await _config).b2COptions;
    // Error handling
    if (error) {
        // Check for forgot password error
        if (error.errorMessage.indexOf('AADB2C90118') > -1) {
            try {
                const azureAdOptions = (await _config).azureAdOptions;
                // Password reset policy/authority
                _app.authority = `${azureAdOptions.instance}/tfp/${azureAdOptions.domain}/${b2COptions.passwordReset}`;
                await login();
            } catch (err) {
                console.error(err);
            }
        }
    } else {
        // We need to reject id tokens that were not issued with the default sign-in policy.
        if (response.tokenType === 'id_token' && response.idToken.claims['tfp'] !== b2COptions.signUpAndSignIn) {
            _app.logout();
        }
    }
};
