import React, { useState, useEffect, useMemo, useRef } from 'react';
import I18n from '../../helper/Localization';
import { TableView } from './TableView';
import { IconButton, getTheme, DefaultButton } from 'office-ui-fabric-react';
import styled from 'styled-components';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import UserDetailDialog from '../dialogs/userDetail/UserDetailDialog';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { postToApi, fetchApiObject } from '../../helper/ApiHelper';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { InfoButton } from '../InfoButton';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import Table from '../Table';
import { Colors } from '../../styles/Globals';

const ActionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-width: max-content;
`;

const InactiveText = styled.div`
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: ${Colors.inactiveGray};
    opacity: 1;
`;

const InfoWrapper = styled.div`
    display: flex;
    width: 100%;
`;

const UserViewContainer = styled.div`
    height: 100%;
    width: 100%;
`;

const SortableTableHeader = styled.div`
    cursor: pointer;
`;

const PanelRow = styled.div`
    display: flex;
    min-width: max-content;
`;

const PanelHeaderHeadline = styled.div`
    font-weight: bold;
    width: 100px;
`;

const PanelHeader = styled.div`
    display: flex;
    width: 100%;
    padding: 20px;
    justify-content: flex-start;
    flex-direction: column;
`;

const PanelBody = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 200px);
    overflow-y: hidden;
    @media only screen and (max-width: 1400px) {
        height: calc(100vh - 225px);
    }
`;

const PanelFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 20px;
`;

/**
 * component to display the users table
 */
export const UsersView = () => {
    /**
     * fluent ui theme
     */
    const theme = getTheme();

    /**
     * The open state of the debtor panel.
     */
    const [isDebtorPanelOpen, setIsDebtorPanelOpen] = useState(false);

    /**
     * The users to display in the table.
     */
    const [users, setUsers] = useState([]);

    /**
     * The selected user
     */
    const [selectedUser, setSelectedUser] = useState(null);

    /**
     * Visibility state of the edit user dialog
     */
    const [editUserHidden, setEditUserHidden] = useState(true);

    /**
     * Put the debtors in the redux store after loading
     */
    const changeDebtors = useStoreActions((actions) => actions.data.changeDebtors);

    /**
     * Use debtors from redux for caching
     */
    const debtors = useStoreState((state) => state.data.debtors);

    /**
     * visible state for the busy indicator
     */
    const [isBusy, setIsBusy] = useState(false);

    /**
     * Whether the table search is busy or not.
     */
    const [isSearchBusy, setIsSearchBusy] = useState(false);

    /**
     * The maximum number of pages of the current fetched data.
     */
    const [maxPages, setMaxPages] = useState(1);

    /**
     * The current selected data page.
     */
    const [currentPage, setCurrentPage] = useState(1);

    /**
     * State of the current sort direction. true for ASC, false for DESC.
     */
    const [sortAscending, setSortAscending] = useState(true);

    /**
     * State of the current sort property.
     */
    const [sortPropertyName, setSortPropertyName] = useState('DisplayName');

    /**
     * State of the current sort property.
     */
    const [searchValue, setSearchValue] = useState('');

    /**
     * Whether the user has sorted the table or not.
     */
    const hasSorted = useRef(false);

    const previousSearchValue = useRef('');

    /**
     * fluent ui
     */
    const editButtonStyles = {
        icon: {
            color: 'white',
            fontSize: 10,
            marginBottom: '3px',
        },
        root: {
            border: 0,
            borderRadius: '2px',
            boxShadow: '1px 2px 3px #0000003C',
            backgroundColor: theme.palette.themePrimary,
            width: 28,
            height: 28,
            marginRight: 10,
        },
        rootHovered: {
            backgroundColor: 'none',
        },
        rootPressed: {
            backgroundColor: 'none',
        },
    };

    const viewButtonStyles = {
        icon: {
            color: 'white',
            fontSize: 16,
        },
        root: {
            border: 0,
            borderRadius: '2px',
            boxShadow: '1px 2px 3px #0000003C',
            backgroundColor: theme.palette.themePrimary,
            width: 28,
            height: 28,
        },
        rootHovered: {
            backgroundColor: 'none',
        },
        rootPressed: {
            backgroundColor: 'none',
        },
    };

    const disableButtonStyles = {
        icon: {
            color: 'white',
            fontSize: 10,
            marginBottom: '3px',
        },
        root: {
            border: 0,
            borderRadius: '2px',
            boxShadow: '1px 2px 3px #0000003C',
            backgroundColor: '#6C6C6C',
            width: 28,
            height: 28,
            marginRight: 10,
        },
        rootHovered: {
            backgroundColor: 'none',
        },
        rootPressed: {
            backgroundColor: 'none',
        },
    };

    const cancleButtonStyles = {
        root: {
            border: 'unset',
            fontSize: '10px',
        },
    };

    const enableButtonStyles = {
        icon: {
            color: 'white',
            fontSize: 10,
            marginBottom: '3px',
        },
        root: {
            border: 0,
            borderRadius: '2px',
            boxShadow: '1px 2px 3px #0000003C',
            backgroundColor: theme.palette.black,
            width: 28,
            height: 28,
            marginRight: 10,
        },
        rootHovered: {
            backgroundColor: 'none',
        },
        rootPressed: {
            backgroundColor: 'none',
        },
    };

    /**
     * Load debtor on component startup
     */
    useEffect(() => {
        if (debtors && debtors.length > 0) {
            // debtors are already loaded to the store
            return;
        }
        // load the debtors to redux
        fetchApiObject('v1.0/Debtor').then((debtors) => changeDebtors(debtors));
    }, []);

    /**
     * Fetches the users.
     */
    const fetchData = async () => {
        try {
            if (!debtors) {
                return;
            }
            setIsBusy(true);
            const defaultReqBody = {
                itemsPerPage: 10,
                sortDirection: sortAscending ? 1 : 2,
                sortPropertyName: sortPropertyName,
            };
            let reqBody;
            if (searchValue && searchValue !== '') {
                setIsSearchBusy(true);
                if (searchValue !== previousSearchValue.current) {
                    reqBody = {
                        filterText: searchValue.toLowerCase(),
                        pageToDeliver: 1,
                        ...defaultReqBody,
                    };
                } else {
                    reqBody = {
                        filterText: searchValue.toLowerCase(),
                        pageToDeliver: currentPage,
                        ...defaultReqBody,
                    };
                }
            } else {
                if (previousSearchValue.current !== '') {
                    setIsSearchBusy(true);
                }
                reqBody = {
                    pageToDeliver: currentPage,
                    ...defaultReqBody,
                };
            }
            const apiUsers = await postToApi('v1.0/User/Paged', reqBody);
            setMaxPages(apiUsers.pagesAvailable);
            const tableUsers = apiUsers.results.map((user) => {
                // if a user has null as debtor-assignments, all debtors are implicitly assigned
                let userDebtors = debtors;
                if (user.isNormalUser) {
                    userDebtors = user.debtorIds.map((id) => debtors.find((n) => n.id === id));
                }
                return {
                    ...user,
                    debtors: userDebtors,
                };
            });
            setUsers(tableUsers);
        } catch (error) {
            setMaxPages(1);
            setCurrentPage(1);
            setUsers([]);
        } finally {
            previousSearchValue.current = searchValue;
            setIsBusy(false);
            setIsSearchBusy(false);
        }
    };

    /**
     * Some users are assigned to multiple debtors.
     * Map the users so that the table has an entry per user and debtor.
     */
    useEffect(() => {
        fetchData();
    }, [debtors, currentPage, setCurrentPage, searchValue, setSearchValue, sortAscending, setSortAscending, sortPropertyName, setSortPropertyName]);

    /**
     * Click handler for activation / deactivation of users.
     * @param {*} event The click event.
     * @param {*} target The targeted row in the users table.
     */
    const toggleIsActive = async (event, target) => {
        setIsBusy(true);
        event.stopPropagation();
        const idx = users.findIndex((x) => x.id === target.id && x.debtorId === target.debtorId);
        const reqBody = {
            id: users[idx].id,
            isActive: !users[idx].isActive,
        };
        try {
            const updatedUser = await postToApi('v1.0/User/UpdateActive', reqBody);
            if (updatedUser) {
                users.forEach((user) => {
                    if (user.id === target.id) {
                        user.isActive = !user.isActive;
                    }
                });
                const updatedUsers = [...users];
                setUsers(updatedUsers);
            }
        } catch (error) {
            console.error(error.text);
        } finally {
            setIsBusy(false);
        }
    };

    /**
     * Sorts the table by the given property name considering the sort direction state.
     */
    const handlePropertySort = (propertyName) => {
        if (sortPropertyName === propertyName) {
            setSortAscending(!sortAscending);
        } else {
            setSortAscending(true);
            setSortPropertyName(propertyName);
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'interactions',
                width: 80,
                maxWidth: -1,
                minWidth: 100,
                Cell: (table) => {
                    const isNormalUser = table.row.original.isNormalUser;
                    const isActive = table.row.original.isActive;
                    return (
                        <ActionWrapper>
                            <IconButton
                                disabled={!isNormalUser}
                                styles={editButtonStyles}
                                iconProps={{ iconName: 'pen-solid-svg' }}
                                title="Edit"
                                ariaLabel="Edit"
                                onClick={() => {
                                    setSelectedUser(table.row.original);
                                    setEditUserHidden(false);
                                }}
                            />
                            {isActive ? (
                                <IconButton
                                    styles={disableButtonStyles}
                                    iconProps={{ iconName: 'disable-white-svg' }}
                                    title="Disable"
                                    ariaLabel="Disable"
                                    onClick={(event) => toggleIsActive(event, table.row.original)}
                                />
                            ) : (
                                <IconButton
                                    styles={enableButtonStyles}
                                    iconProps={{ iconName: 'circle-notch-solid-svg' }}
                                    title="Enable"
                                    ariaLabel="Enable"
                                    onClick={(event) => toggleIsActive(event, table.row.original)}
                                />
                            )}
                        </ActionWrapper>
                    );
                },
            },
            {
                Header: (
                    <SortableTableHeader onClick={() => handlePropertySort('Email')}>
                        {I18n.get().t('Table_Header_Email')}
                        {sortPropertyName === 'Email' && <FontIcon iconName={sortAscending ? 'SortUp' : 'SortDown'} />}
                    </SortableTableHeader>
                ),
                accessor: 'email',
                width: 175,
                minWidth: 175,
                Cell: (table) => {
                    return table.row.original.isActive ? table.cell.value : <InactiveText>{table.cell.value}</InactiveText>;
                },
            },
            {
                Header: (
                    <SortableTableHeader
                        onClick={() => {
                            hasSorted.current = true;
                            handlePropertySort('DisplayName');
                        }}
                    >
                        {I18n.get().t('Table_Header_DisplayName')}
                        {hasSorted.current != false && sortPropertyName === 'DisplayName' && <FontIcon iconName={sortAscending ? 'SortUp' : 'SortDown'} />}
                    </SortableTableHeader>
                ),
                accessor: 'displayName',
                width: 110,
                Cell: (table) => {
                    return table.row.original.isActive ? table.cell.value : <InactiveText>{table.cell.value}</InactiveText>;
                },
            },
            {
                Header: (
                    <InfoWrapper>
                        {I18n.get().t('Table_Header_Debtor')}
                        <InfoButton
                            id="debtor_usersview"
                            headlineText={I18n.get().t('Debtor_TeachingBubble_Headline')}
                            teachingBubbleContent={I18n.get().t('Debtor_TeachingBubble_Text')}
                        />
                    </InfoWrapper>
                ),
                accessor: 'debtors',
                width: 70,
                Cell: (table) => {
                    const openDebtorPanelButton = (
                        <IconButton
                            styles={viewButtonStyles}
                            iconProps={{ iconName: 'OpenPane' }}
                            onClick={() => {
                                setSelectedUser(table.row.original);
                                setIsDebtorPanelOpen(true);
                            }}
                        />
                    );
                    const cellContent =
                        window.innerWidth > 1400 ? (
                            <TooltipHost content={I18n.get().t('View_All_Debtors_Tooltip')}>{openDebtorPanelButton}</TooltipHost>
                        ) : (
                            <>{openDebtorPanelButton}</>
                        );
                    return table.row.original.isActive ? cellContent : <InactiveText>{cellContent}</InactiveText>;
                },
            },
            {
                Header: (
                    <SortableTableHeader onClick={() => handlePropertySort('Company')}>
                        {I18n.get().t('Table_Header_Company')}
                        {sortPropertyName === 'Company' && <FontIcon iconName={sortAscending ? 'SortUp' : 'SortDown'} />}
                    </SortableTableHeader>
                ),
                accessor: 'company',
                width: 125,
                Cell: (table) => {
                    return table.row.original.isActive ? table.cell.value : <InactiveText>{table.cell.value}</InactiveText>;
                },
            },
            {
                Header: (
                    <SortableTableHeader onClick={() => handlePropertySort('Department')}>
                        {I18n.get().t('Table_Header_Department')}
                        {sortPropertyName === 'Department' && <FontIcon iconName={sortAscending ? 'SortUp' : 'SortDown'} />}
                    </SortableTableHeader>
                ),
                accessor: 'department',
                width: 80,
                Cell: (table) => {
                    return table.row.original.isActive ? table.cell.value : <InactiveText>{table.cell.value}</InactiveText>;
                },
            },
        ],
        [users, sortPropertyName, setSortPropertyName]
    );

    /**
     * Body of the panel
     */
    const onRenderBody = () => {
        const debtorTableColumns = [
            {
                Header: 'Nummer',
                accessor: 'identifier',
            },
            {
                Header: 'Name',
                accessor: 'companyName',
            },
        ];

        return (
            <PanelBody>
                <Table data={selectedUser && selectedUser.debtors} columns={debtorTableColumns} />
            </PanelBody>
        );
    };

    /**
     * Footer content of the panel.
     */
    const onRenderFooterContent = () => (
        <PanelFooter>
            <DefaultButton styles={cancleButtonStyles} text={I18n.get().t('DebtorPanel_Close')} onClick={() => setIsDebtorPanelOpen(false)} />
        </PanelFooter>
    );

    /**
     * Header content of the panel.
     */
    const onRenderHeader = () => (
        <PanelHeader>
            <PanelRow>
                <PanelHeaderHeadline>{I18n.get().t('DebtorPanel_Name')}</PanelHeaderHeadline>
                <div>{`${selectedUser.displayName !== null && selectedUser.displayName !== undefined ? selectedUser.displayName : ''}`}</div>
            </PanelRow>
            <PanelRow>
                <PanelHeaderHeadline>{I18n.get().t('DebtorPanel_Mail')}</PanelHeaderHeadline>
                <div>{`${selectedUser.email !== null && selectedUser.email !== undefined ? selectedUser.email : ''}`}</div>
            </PanelRow>
            <PanelRow>
                <PanelHeaderHeadline>{I18n.get().t('DebtorPanel_Company')}</PanelHeaderHeadline>
                <div>{`${selectedUser.company !== null && selectedUser.company !== undefined ? selectedUser.company : ''}`}</div>
            </PanelRow>
        </PanelHeader>
    );

    return (
        <UserViewContainer>
            <Panel
                allowTouchBodyScroll
                isOpen={isDebtorPanelOpen}
                isFooterAtBottom={true}
                onDismiss={() => setIsDebtorPanelOpen(false)}
                isLightDismiss
                type={PanelType.large}
                closeButtonAriaLabel={I18n.get().t('DebtorPanel_Close')}
                onRenderHeader={onRenderHeader}
                onRenderBody={onRenderBody}
                onRenderFooter={onRenderFooterContent}
            />
            <UserDetailDialog isHidden={editUserHidden} close={() => setEditUserHidden(true)} selectedUser={selectedUser} userUpdated={fetchData} />
            <TableView
                isBusy={isBusy}
                data={users}
                columns={columns}
                viewName={I18n.get().t('ViewName_Users')}
                maxPages={maxPages}
                currentPage={currentPage}
                setCurrentPage={(page) => setCurrentPage(page)}
                setSearchValue={(searchValue) => setSearchValue(searchValue)}
                isSearchBusy={isSearchBusy}
            />
        </UserViewContainer>
    );
};
