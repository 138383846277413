import React, { useEffect, useState } from 'react';
import { routes } from './Routes';
import { useStoreActions } from 'easy-peasy';
import History from './components/BrowserHistory';
import { getLocationFromPath } from './helper/NavigationHelper';
import { isUserAdministrator, isUserAccountManager } from './helper/RoleHelper';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './components/AppInsights';
import I18n from './helper/Localization';
import { signIn, getToken } from './helper/AuthHelper';
import { fetchApiObject } from './helper/ApiHelper';
import { LandscapeTipOverlay } from './components/LandscapeTipOverlay';

/**
 * Global object that holds the general options defined in the appsettings.json.
 */
export let generalOptions;

// retrieve configuration values from the server side
export const _config = fetch('api/Configuration').then((config) => config.json());

// I18n initialization
const i18nPromise = I18n.getPromise();

/** Main app component with an initialized redux store and theme. */
const OnlineApp = () => {
  /**
   * Action to add a role to the store state.
   */
  const addRole = useStoreActions((actions) => actions.user.addRole);
  /**
   * Whether the app is configured correctly for online mode or not.
   */
  const [isInitialized, setInitialized] = useState(false);
  
  useEffect(() => {
    // Resolve the configuration.
    _config.then((config) => {
      // Sign in the user.
      signIn().then((signedIn) => {
        if (!signedIn) {
          // Login Redirect will happen, no need to render.
          return;
        }
        // Set the general options.
        generalOptions = config.generalOptions;
        // Populate token cache.
        getToken([config.apiOptions.scope]);
        // Fetch the roles.
        const rolesPromise = fetchApiObject('v1.0/User/Roles');
        Promise.all([i18nPromise, rolesPromise]).then((results) => {
          const userRoles = results[1];
          // Adding each role to the redux store.
          userRoles.forEach((userRole) => {
            addRole(userRole);
            setInitialized(true);
          });
          const targetPath = getLocationFromPath(History.location.pathname);
          // User might be global administrator for all debtors or for a subset of debtors.
          const isAdministrator = isUserAdministrator(userRoles);
          const isAccountManager = isUserAccountManager(userRoles);
          if (!isAdministrator && !isAccountManager) {
            if (targetPath !== '/orders' && targetPath !== '/results') {
              // If the deep link targets a non authorized path as a regular user, send him where he should be.
              History.replace('/orders');
            }
          } else {
            if (targetPath === '/') {
              History.replace('/orders');
            }
          }
        });
      });
    });
  }, [addRole]);

  return isInitialized ? (
    <>
      <LandscapeTipOverlay />
      {routes}
    </>
  ) : null;
};

export default withAITracking(reactPlugin, OnlineApp, OnlineApp.name, 'app-container');

export const displayName = OnlineApp.name;
