import React from 'react';
import { Route } from 'react-router-dom';
import Layout from './Layout';

/**
 * Route using the main layout.
 */
export const LayoutRoute = ({ component: Component, ...rest }) => {
    const render = (matchProperties) => (
        <Layout>
            <Component {...matchProperties} />
        </Layout>
    );
    return (
        <Route {...rest} render={render} />
    );
};
