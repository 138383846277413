import React from 'react';
import { useTable, useSortBy, useFlexLayout } from 'react-table';
import styled from 'styled-components';
import I18n from '../helper/Localization';
import { Colors } from '../styles/Globals';

const TableWrapper = styled.div`
    min-width: max-content !important;
    max-height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const Header = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
`;

const HeaderLabel = styled.div`
    font-weight: bold;
    color: #8b8b8b;
    font-size: 14px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledRow = styled.div`
    padding: 15px 35px;
    border-bottom: solid 1px ${Colors.borderGray};
    :hover {
        background-color: ${Colors.hoverLightGray};
    }
`;

const StyledCell = styled.div`
    margin-top: auto;
    margin-bottom: auto;
    word-wrap: break-word;
    font-size: 14px;
`;

const HeaderRowWrapper = styled.div`
    border-bottom: solid 1px ${Colors.borderGray};
    padding: 10px 35px 10px 35px;
    margin-right: ${(props) => props.marginRight + 'px'};
`;

const BodyWrapper = styled.div`
    display: block;
    width: 100%;
    overflow-y: auto;
    height: auto;
`;

/**
 *
 */
const getTableScrollbarWidth = () => {
    const table = document.getElementById('table-body');
    let scrollbarWidth = 0;
    if (table) {
        scrollbarWidth = table.offsetWidth - table.clientWidth;
    }
    return scrollbarWidth;
};

/**
 * Reset the scroll bar after new data is available.
 */
const resetScroll = () => {
    let tableBody = document.getElementById('table-body');
    tableBody.scrollTop = 0;
};

/**
 * Generic table which supports basic sorting.
 */
const Table = (props) => {
    const [scrollbarWidth, setScrollbarWidth] = React.useState(getTableScrollbarWidth());

    React.useEffect(() => {
        function triggerRender() {
            // Set value to scrollbar width result, thus not triggering a re-render, if the height is the same.
            setScrollbarWidth(getTableScrollbarWidth());
        }
        window.addEventListener('resize', triggerRender);
        setScrollbarWidth(getTableScrollbarWidth());
    });

    React.useEffect(() => {
        resetScroll();
    }, [props.data]);

    // Case insensitive sorting for strings, numbers and dates.
    const compareIgnoreCase = (value1, value2) => {
        if (value1 === value2) {
            return 0;
        }
        if (value1 == null) {
            return -1;
        }
        if (value2 == null) {
            return 1;
        }
        if (typeof value1 === 'string' && typeof value2 === 'string') {
            return value1.toLowerCase().localeCompare(value2.toLowerCase());
        }
        if (typeof value1 === 'number' && typeof value2 === 'number') {
            return value1 > value2 ? 1 : -1;
        }
        if (value1 instanceof Date && value2 instanceof Date) {
            return value1 > value2 ? 1 : -1;
        }
        console.warn('Unknown comparison types:', typeof value1, typeof value2);
    };

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 150,
            maxWidth: 200,
        }),
        []
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            autoResetSortBy: false,
            columns: props.columns,
            data: props.data,
            defaultColumn,
            disableSortBy: props.disableSort,
            sortTypes: {
                alphanumeric: (row1, row2, columnName) => {
                    return compareIgnoreCase(row1.values[columnName], row2.values[columnName]);
                },
            },
        },
        useSortBy,
        useFlexLayout
    );

    return (
        <TableWrapper {...getTableProps()}>
            {(props.showHeader || props.showHeader == null) && (
                <HeaderRowWrapper id="header-row" marginRight={scrollbarWidth}>
                    {headerGroups.map((headerGroup, i) => (
                        <div {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, i) => (
                                <div
                                    {...column.getHeaderProps(column.getSortByToggleProps({ title: I18n.get().t('Table_Tooltip_SortBy') }))}
                                    style={column.getHeaderProps(column.getSortByToggleProps({ title: I18n.get().t('Table_Tooltip_SortBy') })).style}
                                >
                                    <Header>
                                        <HeaderLabel>{column.render('Header')}</HeaderLabel>
                                        {column.isSorted && <span>{column.isSortedDesc ? '↓' : '↑'}</span>}
                                    </Header>
                                </div>
                            ))}
                        </div>
                    ))}
                </HeaderRowWrapper>
            )}
            <BodyWrapper id="table-body" {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <StyledRow {...row.getRowProps()} onClick={() => props.onRowClick && props.onRowClick(row.original)}>
                            {row.cells.map((cell) => {
                                return <StyledCell {...cell.getCellProps()}>{cell.render('Cell')}</StyledCell>;
                            })}
                        </StyledRow>
                    );
                })}
            </BodyWrapper>
        </TableWrapper>
    );
};

export default Table;
