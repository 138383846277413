import React, { useEffect, useState } from 'react';
import { LandscapeTipOverlay } from './components/LandscapeTipOverlay';
import { OfflineHearingTest } from './components/hearingTest/OfflineHearingTest';
import I18n from './helper/Localization';

/** Offline app component that is used when the user is in offline mode. */
const OfflineApp = () => {
  // I18n initialization
  const i18nPromise = I18n.getPromise();

  /**
   * Whether the component is initialized correctly.
   */
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    Promise.all([i18nPromise]).then(() => {
      setIsInitialized(true);
    });
  }, [i18nPromise]);

  return isInitialized ? (
    <>
      <LandscapeTipOverlay />
      <OfflineHearingTest />
    </>
  ) : null;
};

export default OfflineApp;
