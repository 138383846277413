import React from 'react';
import { FontIcon } from 'office-ui-fabric-react';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';

const success = mergeStyles({
  backgroundColor: '#12B361',
  textAlign: 'center',
  justifyContent: 'center',
  margin: 0,
  borderRadius: 2,
  boxShadow: '1px 2px 3px #0000003C',
  fontSize: 18,
  color: 'white',
  height: 28,
  width: 28,
  paddingLeft: 3
});

const error = mergeStyles({
  backgroundColor: '#EA1E1E',
  textAlign: 'center',
  justifyContent: 'center',
  margin: 0,
  borderRadius: 2,
  boxShadow: '1px 2px 3px #0000003C',
  fontSize: 18,
  color: 'white',
  height: 28,
  width: 28,
  paddingLeft: 3
});

const pending = mergeStyles({
  backgroundColor: '#666666',
  textAlign: 'center',
  justifyContent: 'center',
  margin: 0,
  borderRadius: 2,
  boxShadow: '1px 2px 3px #0000003C',
  fontSize: 18,
  color: 'white',
  height: 28,
  width: 28,
  paddingLeft: 3
});

/**
 * helper method that returns the correct status icon
 * @param param
 */
const renderSwitch = (param) => {
  switch (param) {
    case true:
      return <FontIcon iconName="StatusCircleCheckmark" className={success} />;
    case false:
      return <FontIcon iconName="StatusCircleErrorX" className={error} />;
    default:
      return <FontIcon iconName="StatusCircleQuestionMark" className={pending} />;
  }
};

/**
 * a basic status icon that takes the status as prop
 * @param status {successState} The success state.
 */
export const StatusIcon = ({ status }) => {
  return renderSwitch(status);
};
