import React, { useState } from 'react';
import { DatePicker, DayOfWeek, mergeStyleSets } from 'office-ui-fabric-react';
import I18n from '../helper/Localization';

/**
 * Basic date picker with text based on current language.
 */
export const BasicDatePicker = (props) => {
  /**
   * The current displayed date.
   */
  const [value, setValue] = useState(props.defaultValue);

  const DayPickerStrings = {
    months: [
      I18n.get().t('DatePicker_January'),
      I18n.get().t('DatePicker_February'),
      I18n.get().t('DatePicker_March'),
      I18n.get().t('DatePicker_April'),
      I18n.get().t('DatePicker_May'),
      I18n.get().t('DatePicker_June'),
      I18n.get().t('DatePicker_July'),
      I18n.get().t('DatePicker_August'),
      I18n.get().t('DatePicker_September'),
      I18n.get().t('DatePicker_October'),
      I18n.get().t('DatePicker_November'),
      I18n.get().t('DatePicker_December')
    ],

    shortMonths: [
      I18n.get().t('DatePicker_ShortJan'),
      I18n.get().t('DatePicker_ShortFeb'),
      I18n.get().t('DatePicker_ShortMar'),
      I18n.get().t('DatePicker_ShortApr'),
      I18n.get().t('DatePicker_ShortMay'),
      I18n.get().t('DatePicker_ShortJun'),
      I18n.get().t('DatePicker_ShortJul'),
      I18n.get().t('DatePicker_ShortAug'),
      I18n.get().t('DatePicker_ShortSep'),
      I18n.get().t('DatePicker_ShortOct'),
      I18n.get().t('DatePicker_ShortNov'),
      I18n.get().t('DatePicker_ShortDec')
    ],

    days: [
      I18n.get().t('DatePicker_Sunday'),
      I18n.get().t('DatePicker_Monday'),
      I18n.get().t('DatePicker_Tuesday'),
      I18n.get().t('DatePicker_Wednesday'),
      I18n.get().t('DatePicker_Thursday'),
      I18n.get().t('DatePicker_Friday'),
      I18n.get().t('DatePicker_Saturday')
    ],

    shortDays: [
      I18n.get().t('DatePicker_ShortSunday'),
      I18n.get().t('DatePicker_ShortMonday'),
      I18n.get().t('DatePicker_ShortTuesday'),
      I18n.get().t('DatePicker_ShortWednesday'),
      I18n.get().t('DatePicker_ShortThursday'),
      I18n.get().t('DatePicker_ShortFriday'),
      I18n.get().t('DatePicker_ShortSaturday')
    ]
  };

  const controlClass = mergeStyleSets({
    control: {
      margin: '0 0 15px 0',
      minWidth: '390px',
      width: '390px'
    }
  });

  /**
   * Formats the date that is selected.
   * @param date the date to display
   */
  const onFormatDate = (date) => {
    const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date);
    return day + '.' + month + '.' + year;
  };

  return (
    <DatePicker
      value={value}
      formatDate={onFormatDate}
      className={controlClass.control}
      firstDayOfWeek={DayOfWeek.Monday}
      strings={DayPickerStrings}
      placeholder="Select a date..."
      ariaLabel="Select a date"
      maxDate={props.maxDate}
      onSelectDate={(date) => {
        setValue(date);
        props.onChangeCallback(date);
      }}
    />
  );
};
