/**
 * expects a datetime string and returns a formatted date string.
 */
 export const toFormattedDate = (locale, dateTimeString) => {
    const date = new Date(dateTimeString);
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    };
    return date.toLocaleDateString(locale, options);
  };
  /**
   * expects a datetime string and returns a formatted date and time string.
   */
  export const toFormattedDateTime = (locale, dateTimeString) => {
    const date = new Date(dateTimeString);
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return date.toLocaleString(locale, options);
  };
  /**
   * expects a datetime string and returns a formatted date and time string.
   */
  export const toCustomFormattedDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${('0' + day).slice(-2)}.${('0' + month).slice(-2)}.${year} - ${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)} Uhr`;
  };
  