import React, { useState } from 'react';
import styled from 'styled-components';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import I18n from '../../../helper/Localization';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PrimaryButton, DefaultButton, getTheme } from 'office-ui-fabric-react';
import { Colors } from '../../../styles/Globals';
import { EndCustomerNumberTag } from './EndCustomerNumberTag';

const PanelBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 24px;
`;

const InfoTextWrapper = styled.div`
    margin: 20px 0 10px 0;
`;

const AddCustomerNumberContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: space-between;
`;

const PanelFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
`;

const TagContainer = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    margin-top: 25px;
`;

/**
 * Dialog to edit and review the end customer numbers of a user.
 */
export const EndCustomerDialog = (props) => {
    const theme = getTheme();

    /**
     * The current tag value that the user entered in the input field.
     */
    const [currentTagValue, setCurrentTagValue] = useState('');

    /**
     * Props of the add icon.
     */
    const addIconProps = {
        iconName: 'Add',
    };

    /**
     * Styles of the add button.
     */
    const addButtonStyles = {
        root: {
            maxWidth: 23,
            minWidth: 23,
            maxHeight: 23,
            marginLeft: 10,
        },
        icon: {
            color: 'white',
            fontSize: 14,
            fontWeight: 'bolder',
        },
    };

    /**
     * Styles of the cancel button.
     */
    const cancleButtonStyles = {
        root: {
            border: 'unset',
            fontSize: '10px',
            marginLeft: '15px',
        },
    };

    /**
     * Styles of the customer number input field.
     */
    const textFieldStyles = {
        root: {
            width: '100%',
            fontSize: 10,
        },
        field: {
            height: 23,
            paddingLeft: 20,
        },
        fieldGroup: {
            height: 24,
            border: `0.5px solid ${Colors.borderGray};`,
        },
        errorMessage: {
            fontSize: 10,
        },
    };

    /**
     * Callback to dismiss the panel.
     */
    const onDismiss = () => {
        setCurrentTagValue('');
        props.closeCallback();
    };

    /**
     * Add a new end customer number to the list of customer numbers.
     */
    const addNewEndCustomerNumber = () => {
        if (currentTagValue && currentTagValue !== '') {
            // Create shallow copy of current end customer numbers.
            const tmpEndCustomerNumbers = [...props.endCustomerNumbers];
            // Add the new end customer number.
            tmpEndCustomerNumbers.push(currentTagValue);
            // Update the list state.
            props.setEndCustomerNumbers([...tmpEndCustomerNumbers]);
            // Reset the user tag input.
            setCurrentTagValue('');
        }
    };

    /**
     * Remove an end customer number by it's index.
     */
    const removeEndCustomerNumber = (index) => {
        // Create shallow copy of the current end customer numbers.
        const tmpEndCustomerNumbers = [...props.endCustomerNumbers];
        // Remove the item.
        tmpEndCustomerNumbers.splice(index, 1);
        // Update the list state.
        props.setEndCustomerNumbers([...tmpEndCustomerNumbers]);
    };

    /**
     * Body of the panel.
     */
    const onRenderBody = () => (
        <PanelBody>
            <InfoTextWrapper>{I18n.get().t('EndCustomerDialog_InfoText')}</InfoTextWrapper>
            <AddCustomerNumberContainer>
                <TextField
                    styles={textFieldStyles}
                    placeholder={I18n.get().t('EndCustomerDialog_InputField_Placeholder')}
                    value={currentTagValue}
                    onChange={(event, newValue) => {
                        if (newValue || newValue === '') {
                            setCurrentTagValue(newValue);
                        }
                    }}
                />
                <PrimaryButton disabled={!currentTagValue || currentTagValue === ''} iconProps={addIconProps} styles={addButtonStyles} onClick={addNewEndCustomerNumber} />
            </AddCustomerNumberContainer>
            <TagContainer>
                {props.endCustomerNumbers.map((endCustomerNumber, i) => (
                    <EndCustomerNumberTag
                        backgroundColor={i % 2 === 0 ? theme.palette.black : Colors.altGray}
                        endCustomerNumber={endCustomerNumber}
                        callback={() => removeEndCustomerNumber(i)}
                    />
                ))}
            </TagContainer>
        </PanelBody>
    );

    /**
     * Footer of the panel.
     */
    const onRenderFooter = () => (
        <PanelFooter>
            <DefaultButton styles={cancleButtonStyles} onClick={onDismiss} text={I18n.get().t('EndCustomerDialog_Finish')} />
        </PanelFooter>
    );

    return (
        <Panel
            allowTouchBodyScroll
            headerText={I18n.get().t('EndCustomerDialog_HeaderText')}
            closeButtonAriaLabel={I18n.get().t('EndCustomerDialog_Close')}
            type={PanelType.custom}
            customWidth={400}
            onDismiss={onDismiss}
            onRenderBody={onRenderBody}
            onRenderFooter={onRenderFooter}
            isFooterAtBottom
            onOuterClick={() => {
                // NOTE: This is needed to prevent the panel from closing when the user interacts with the dialog.
            }}
            isOpen={props.isOpen}
        />
    );
};
