import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import I18n from '../../helper/Localization';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { getTheme } from '@fluentui/react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { BasicDatePicker } from '../BasicDatePicker';
import { postToApi } from '../../helper/ApiHelper';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { msalApp } from '../../helper/AuthHelper';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { BusySpinner } from '../busyIndicators/BusySpinner';
import { BasicDialog } from './BasicDialog';

const Headline = styled.div`
    font-size: 26px;
    font-weight: bold;
    color: ${(props) => props.fontColor};
`;

const SubHeadline = styled.div`
    font-size: 14px;
    font-weight: bold;
    color: ${(props) => props.fontColor};
`;

const InputHeadlineContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 75px;
`;

const InputContainerSmall = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const PanelHeader = styled.div`
    display: flex;
    width: 100%;
    padding: 20px;
    justify-content: flex-start;
    flex-direction: column;
`;

const PanelBody = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
    padding: 0 20px;
    min-width: max-content;
`;

const PanelFooter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px 20px;
    position: relative;
`;

const MessageWrapper = styled.div`
    position: absolute;
    left: 0;
    top: -20px;
    width: 100%;
    visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
`;

const FlexEnd = styled.div`
    display: flex;
    padding-top: 5px;
    justify-content: flex-end;
`;

const InfoTextWrapper = styled.div`
    display: flex;
    word-wrap: break-word;
    max-width: 300px;
`;

/**
 * Dialog to submit a manual test result.
 */
export const ManualTestDialog = (props) => {
    /**
     * The state of the test date.
     */
    const [dateExecuted, setDateExecuted] = useState(new Date());
    /**
     * visible state for the busy indicator
     */
    const [isBusy, setIsBusy] = useState(false);
    /**
     * The state of the left measurement without hearing protection.
     */
    const [measurementLeftWithoutProtection, setMeasurementLeftWithoutProtection] = useState('');
    /**
     * The state of the left measurement with hearing protection.
     */
    const [measurementLeftWithProtection, setMeasurementLeftWithProtection] = useState('');
    /**
     * The state of the right measurement without hearing protection.
     */
    const [measurementRightWithoutProtection, setMeasurementRightWithoutProtection] = useState('');
    /**
     * The state of the right measurement with hearing protection.
     */
    const [measurementRightWithProtection, setMeasurementRightWithProtection] = useState('');
    /**
     * The state of the test result left.
     */
    const [insulationLeft, setInsulationLeft] = useState('');
    /**
     * The state of the test result right.
     */
    const [insulationRight, setInsulationRight] = useState('');
    /**
     * The state of the order position id.
     */
    const [orderPositionId, setOrderPositionId] = useState('');
    /**
     * The state of the order position number.
     */
    const [orderPositionNumber, setOrderPositionNumber] = useState('');
    /**
     * The state of the wearer name.
     */
    const [wearerName, setWearerName] = useState('');
    /**
     * The state of the left success result.
     */
    const [resultLeft, setResultLeft] = useState('');
    /**
     * The state of the right success result.
     */
    const [resultRight, setResultRight] = useState('');
    /**
     * Error message for the MessageBar
     */
    const [errorMessage, setErrorMessage] = useState('');
    /**
     * Success message for the MessageBar
     */
    const [successMessage, setSuccessMessage] = useState('');

    /**
     * Whether the abort dialog is open or not.
     */
    const [isAbortDialogOpen, setIsAbortDialogOpen] = useState(false);

    useEffect(() => {
        setWearerName(props.name);
        setOrderPositionId(props.orderPositionId);
        setOrderPositionNumber(props.orderPositionNumber);
        setDateExecuted(new Date());
        props.resetParentIsBusy();
    }, [props.name, props.orderPositionId, props.isOpen]);

    const theme = getTheme();
    const cancelButtonStyles = {
        root: {
            border: 'unset',
        },
    };

    const dropdownOptions = [
        { key: 'success', text: I18n.get().t('ManualTestDialog_Success') },
        { key: 'fail', text: I18n.get().t('ManualTestDialog_Fail') },
    ];

    /**
     * Resets the states and then closes the dialog.
     */
    const closeDialog = () => {
        setIsAbortDialogOpen(false);
        setErrorMessage('');
        setMeasurementLeftWithoutProtection('');
        setMeasurementLeftWithProtection('');
        setMeasurementRightWithoutProtection('');
        setMeasurementRightWithProtection('');
        setInsulationLeft('');
        setInsulationRight('');
        setResultLeft('');
        setResultRight('');
        setSuccessMessage('');
        setIsBusy(false);
        props.toggleHideDialog();
    };

    /**
     * Handler to set the date executed.
     * @param date the new date.
     */
    const dateExecutedHandler = (date) => {
        setDateExecuted(date);
    };

    /**
     * Validates whether or not an input is empty.
     * Returns a error text to fluent ui.
     * @param value The data that requires validation
     */
    const validateInputNotEmpty = (value) => {
        return value === '' ? I18n.get().t('ManualTestDialog_InvalidInput') : '';
    };

    /**
     * Validates whether an input is a number.
     * @param value The data that requires validation
     */
    const validateInputIsNumber = (value) => {
        const isNan = /^\d+$/.test(value);
        return !isNan ? I18n.get().t('ManualTestDialog_InvalidInput') : '';
    };

    /**
     * Validates if the input fields contain valid data.
     */
    const validateForm = () => {
        if (
            validateInputIsNumber(measurementLeftWithoutProtection) === '' &&
            validateInputIsNumber(measurementLeftWithProtection) === '' &&
            validateInputIsNumber(measurementRightWithoutProtection) === '' &&
            validateInputIsNumber(measurementRightWithProtection) === '' &&
            validateInputIsNumber(insulationLeft) === '' &&
            validateInputIsNumber(insulationRight) === '' &&
            validateInputNotEmpty(orderPositionId) === '' &&
            validateInputNotEmpty(wearerName) === '' &&
            resultLeft !== '' &&
            resultRight !== ''
        ) {
            setErrorMessage('');
            return true;
        }
        setErrorMessage(I18n.get().t('ManualTestDialog_ValidationError'));
        return false;
    };

    /**
     * Submit the hearing test result.
     */
    const submitTestResult = async () => {
        setSuccessMessage('');
        setErrorMessage('');
        if (!validateForm()) {
            return;
        }
        setIsBusy(true);
        const _msalApp = await msalApp();
        const name = _msalApp.getAccount().name;
        const reqBody = {
            dateExecuted: dateExecuted.toISOString(),
            measurementLeftWithoutProtection: measurementLeftWithoutProtection,
            measurementLeftWithProtection: measurementLeftWithProtection,
            measurementRightWithoutProtection: measurementRightWithoutProtection,
            measurementRightWithProtection: measurementRightWithProtection,
            insulationLeft: insulationLeft,
            insulationRight: insulationRight,
            isSuccessfulLeft: resultLeft,
            isSuccessfulRight: resultRight,
            orderPositionId: orderPositionId,
            testerName: name,
        };
        try {
            const newTestResult = await postToApi('v1.0/TestResult', reqBody);
            props.updateTableData(newTestResult);
            setSuccessMessage(I18n.get().t('ManualTestDialog_SavedSuccessful'));
            setTimeout(() => closeDialog(), 2000);
        } catch (error) {
            console.error(error);
            setErrorMessage(I18n.get().t('ManualTestDialog_SaveError'));
            setIsBusy(false);
        }
    };

    /**
     * Open the abort dialog when the input states are not default. If abort dialog must not be open, trigger close.
     */
    const openAbortDialog = () => {
        if (
            insulationLeft !== '' ||
            insulationRight !== '' ||
            measurementLeftWithProtection !== '' ||
            measurementLeftWithoutProtection !== '' ||
            measurementRightWithProtection !== '' ||
            measurementRightWithoutProtection !== '' ||
            resultLeft !== '' ||
            resultRight !== ''
        ) {
            setIsAbortDialogOpen(true);
        } else {
            closeDialog();
        }
    };

    /**
     * Body of the panel
     */
    const onRenderBody = () => {
        return (
            <PanelBody>
                <SubHeadline fontColor={theme.palette.black}>{I18n.get().t('ManualTestDialog_SubHeadline')}</SubHeadline>
                <InputContainer>
                    <TextField
                        styles={{ color: 'black !important' }}
                        validateOnFocusOut
                        validateOnLoad={false}
                        onGetErrorMessage={validateInputIsNumber}
                        disabled
                        label={I18n.get().t('ManualTestDialog_Position')}
                        defaultValue={orderPositionNumber}
                        placeholder=""
                    />
                    <TextField
                        validateOnFocusOut
                        validateOnLoad={false}
                        onGetErrorMessage={validateInputNotEmpty}
                        disabled
                        label={I18n.get().t('ManualTestDialog_Wearer')}
                        value={wearerName}
                        placeholder=""
                        onChange={(_, newValue) => setWearerName(newValue)}
                    />
                </InputContainer>
                <InputHeadlineContainer>
                    <Label>{I18n.get().t('ManualTestDialog_WithoutProtection')}</Label>
                </InputHeadlineContainer>
                <InputContainer>
                    <TextField
                        required
                        validateOnFocusOut
                        validateOnLoad={false}
                        onGetErrorMessage={validateInputIsNumber}
                        label={I18n.get().t('ManualTestDialog_Left')}
                        placeholder=""
                        onChange={(_, newValue) => {
                            if (newValue !== null && newValue !== undefined && newValue !== '') {
                                setMeasurementLeftWithoutProtection(Number(newValue));
                            } else {
                                setMeasurementLeftWithoutProtection('');
                            }
                        }}
                    />
                    <TextField
                        validateOnFocusOut
                        validateOnLoad={false}
                        onGetErrorMessage={validateInputIsNumber}
                        style={{ marginLeft: 'auto' }}
                        required
                        label={I18n.get().t('ManualTestDialog_Right')}
                        placeholder=""
                        onChange={(_, newValue) => {
                            if (newValue !== null && newValue !== undefined && newValue !== '') {
                                setMeasurementRightWithoutProtection(Number(newValue));
                            } else {
                                setMeasurementRightWithoutProtection('');
                            }
                        }}
                    />
                </InputContainer>
                <InputHeadlineContainer>
                    <Label>{I18n.get().t('ManualTestDialog_WithProtection')}</Label>
                </InputHeadlineContainer>
                <InputContainer>
                    <TextField
                        required
                        validateOnFocusOut
                        validateOnLoad={false}
                        onGetErrorMessage={validateInputIsNumber}
                        label={I18n.get().t('ManualTestDialog_Left')}
                        placeholder=""
                        onChange={(_, newValue) => {
                            if (newValue !== null && newValue !== undefined && newValue !== '') {
                                setMeasurementLeftWithProtection(Number(newValue));
                            } else {
                                setMeasurementLeftWithProtection('');
                            }
                        }}
                    />
                    <TextField
                        validateOnFocusOut
                        validateOnLoad={false}
                        onGetErrorMessage={validateInputIsNumber}
                        style={{ marginLeft: 'auto' }}
                        required
                        label={I18n.get().t('ManualTestDialog_Right')}
                        placeholder=""
                        onChange={(_, newValue) => {
                            if (newValue !== null && newValue !== undefined && newValue !== '') {
                                setMeasurementRightWithProtection(Number(newValue));
                            } else {
                                setMeasurementRightWithProtection('');
                            }
                        }}
                    />
                </InputContainer>
                <InputHeadlineContainer>
                    <Label>{I18n.get().t('ManualTestDialog_Result')}</Label>
                </InputHeadlineContainer>
                <InputContainer>
                    <TextField
                        required
                        validateOnFocusOut
                        validateOnLoad={false}
                        onGetErrorMessage={validateInputIsNumber}
                        label={I18n.get().t('ManualTestDialog_Left')}
                        placeholder=""
                        onChange={(_, newValue) => {
                            if (newValue !== null && newValue !== undefined && newValue !== '') {
                                setInsulationLeft(Number(newValue));
                            } else {
                                setInsulationLeft('');
                            }
                        }}
                    />
                    <TextField
                        validateOnFocusOut
                        validateOnLoad={false}
                        onGetErrorMessage={validateInputIsNumber}
                        style={{ marginLeft: 'auto' }}
                        required
                        label={I18n.get().t('ManualTestDialog_Right')}
                        placeholder=""
                        onChange={(_, newValue) => {
                            if (newValue !== null && newValue !== undefined && newValue !== '') {
                                setInsulationRight(Number(newValue));
                            } else {
                                setInsulationRight('');
                            }
                        }}
                    />
                </InputContainer>
                <InputHeadlineContainer>
                    <Label>{I18n.get().t('ManualTestDialog_Status')}</Label>
                </InputHeadlineContainer>
                <InfoTextWrapper>{I18n.get().t('ManualTestDialog_Status_Info')}</InfoTextWrapper>
                <InputContainer>
                    <Dropdown
                        label={I18n.get().t('ManualTestDialog_Left_Result')}
                        options={dropdownOptions}
                        required
                        style={{ minWidth: '187px' }}
                        onChange={(_, newValue) => {
                            if (newValue.key === 'success') {
                                setResultLeft(true);
                            } else {
                                setResultLeft(false);
                            }
                        }}
                    />
                    <Dropdown
                        label={I18n.get().t('ManualTestDialog_Right_Result')}
                        options={dropdownOptions}
                        required
                        style={{ marginLeft: 'auto', minWidth: '187px' }}
                        onChange={(_, newValue) => {
                            if (newValue.key === 'success') {
                                setResultRight(true);
                            } else {
                                setResultRight(false);
                            }
                        }}
                    />
                </InputContainer>
                <InputHeadlineContainer>
                    <Label>{I18n.get().t('ManualTestDialog_Testdate')}</Label>
                </InputHeadlineContainer>
                <InputContainerSmall>
                    <BasicDatePicker defaultValue={dateExecuted} onChangeCallback={dateExecutedHandler} maxDate={new Date()} />
                </InputContainerSmall>
            </PanelBody>
        );
    };

    /**
     * Footer content of the panel.
     */
    const onRenderFooterContent = () => (
        <PanelFooter>
            <MessageWrapper hidden={errorMessage === '' && successMessage === ''}>
                <MessageBar isMultiline={false} messageBarType={successMessage !== '' ? MessageBarType.success : MessageBarType.error}>
                    {successMessage !== '' ? successMessage : errorMessage}
                </MessageBar>
            </MessageWrapper>
            <FlexEnd>
                <BusySpinner isBusy={isBusy} />
                <DefaultButton styles={cancelButtonStyles} onClick={openAbortDialog} text={I18n.get().t('ManualTestDialog_Cancel')} />
                <PrimaryButton disabled={isBusy} onClick={submitTestResult} text={I18n.get().t('ManualTestDialog_Confirm')} />
            </FlexEnd>
        </PanelFooter>
    );

    /**
     * Header content of the panel.
     */
    const onRenderHeader = () => (
        <PanelHeader>
            <Headline fontColor={theme.palette.black}>{I18n.get().t('ManualTestDialog_Headline')}</Headline>
        </PanelHeader>
    );

    return (
        <>
            <Panel
                isOpen={!props.hidden}
                isFooterAtBottom={true}
                onDismiss={openAbortDialog}
                isLightDismiss
                type={PanelType.custom}
                customWidth={'max-content'}
                closeButtonAriaLabel={I18n.get().t('DebtorPanel_Close')}
                onRenderHeader={onRenderHeader}
                onRenderBody={onRenderBody}
                onRenderFooter={onRenderFooterContent}
            />
            <BasicDialog
                hidden={!isAbortDialogOpen}
                title={I18n.get().t('AbortManualTestDialog_Headline')}
                text={I18n.get().t('AbortManualTestDialog_Text')}
                confirmCallback={() => closeDialog()}
                cancelCallback={() => setIsAbortDialogOpen(false)}
            />
        </>
    );
};
