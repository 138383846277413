import React from 'react';
import styled from 'styled-components';
import { BusySpinner } from './busyIndicators/BusySpinner';
import { SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { TextField, IconButton } from 'office-ui-fabric-react';

const SearchBoxWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
`;

const SearchBoxBusyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 10px;
  z-index: 1;
`;

/**
 * Custom search box component.
 */
export const CustomSearchBox = (props) => {
  /**
   * Styles of the search box.
   */
  const searchBoxStyles = {
    root: {
      width: props.width
    },
    fieldGroup: {
      maxHeight: '24px',
      height: '24px',
      borderRadius: '2px',
      padding: '1px 0'
    }
  };

  /**
   * Icon props for the search button.
   */
  const searchButtonIcon = {
    iconName: 'Search'
  };

  /**
   * Styles for the search button.
   */
  const searchButtonStyles = {
    root: {
      position: 'absolute',
      right: 1,
      height: 21,
      width: 30,
      fontSize: 11
    },
    rootHovered: {
      backgroundColor: '#F7F7F7'
    },
    rootPressed: {
      backgroundColor: '#F7F7F7',
      color: 'none'
    },
    rootFocused: {
      outline: 0
    }
  };

  return (
    <SearchBoxWrapper>
      <SearchBoxBusyWrapper>
        <BusySpinner isBusy={props.isBusy} busySize={SpinnerSize.small} />
      </SearchBoxBusyWrapper>
      <TextField
        styles={searchBoxStyles}
        placeholder={props.placeholder}
        value={props.isBusy ? `\t${props.busyText}` : props.searchValue}
        onChange={(_, newValue) => {
          props.updateSearchValue(newValue);
          if (props.updateLastDataUpdate) {
            props.updateLastDataUpdate(new Date());
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            props.applySearch();
          }
        }}
      />
      <IconButton styles={searchButtonStyles} iconProps={searchButtonIcon} onClick={props.applySearch} />
    </SearchBoxWrapper>
  );
};
