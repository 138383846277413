import React from 'react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import I18n from '../../../helper/Localization';
import styled from 'styled-components';
import { Colors } from '../../../styles/Globals';

const DialogContent = styled.p`
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  color: ${Colors.dialogGray};
`;

/**
 * Dialog to let the user confirm the conditions and note of the manual order creation.
 */
export const ConfirmationDialog = (props) => {
  /**
   * Properties of the dialog content.
   */
  const dialogContentProps = {
    type: DialogType.normal,
    title: I18n.get().t('ConfirmOpenManualOrderDialog_Title'),
    closeButtonAriaLabel: 'Close'
  };

  const cancleButtonStyles = {
    root: {
      border: 'unset'
    }
  };

  return (
    <Dialog hidden={!props.isVisible} dialogContentProps={dialogContentProps}>
      <DialogContent>{I18n.get().t('ConfirmOpenManualOrderDialog_Text')}</DialogContent>
      <DialogFooter>
        <PrimaryButton text={I18n.get().t('ConfirmOpenManualOrderDialog_Confirm')} onClick={props.confirmCallback} />
        <DefaultButton styles={cancleButtonStyles} text={I18n.get().t('ConfirmOpenManualOrderDialog_Cancel')} onClick={props.cancelCallback} />
      </DialogFooter>
    </Dialog>
  );
};
