import React, { useMemo, useEffect, useState } from 'react';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { postToApi, fetchApiString, fetchApiObject } from '../../../helper/ApiHelper';
import { toFormattedDate } from '../../../helper/DateFormatHelper';
import { useStoreState } from 'easy-peasy';
import DebtorSearch from '../inviteUser/DebtorSearch';
import DebtorList from '../inviteUser/DebtorList';
import styled from 'styled-components';
import I18n from '../../../helper/Localization';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { BusySpinner } from '../../busyIndicators/BusySpinner';
import { Colors } from '../../../styles/Globals';
import { BasicDialog } from '../BasicDialog';
import { SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { OpenEndCustomerDialogButton } from '../endCustomerDialog/OpenEndCustomerDialogButton';
import { EndCustomerDialog } from '../endCustomerDialog/EndCustomerDialog';

/**
 * styled components
 */
const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 300px;
    justify-content: space-between;
`;

const ExportBusyWrapper = styled.div`
    margin-right: 10px;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const FlexRowSpace = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const FlexRowEnd = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;

const BottomContainer = styled.div`
    margin-top: auto;
    display: flex;
    flex: 1;
    overflow: hidden;
`;

const UserDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-right: 30px;
`;

const UserData = styled.div`
    border: 1px solid var(--unnamed-color-f0f0f0);
    padding: 20px 20px 10px 20px;
    background-color: ${Colors.backgroundGray};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    margin: 0px;
`;

const Data = styled.div`
    text-align: left;
    margin-bottom: 10px;
    font: 400 14px/15px NeueHaasGroteskText W01;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
`;

const Label = styled.div`
    text-align: left;
    font: Bold 10px/15px NHaasGroteskTXW01-75Bd;
    letter-spacing: 0px;
    color: ${Colors.inactiveGray};
    opacity: 1;
`;

const Text = styled.p`
    color: var(--unnamed-color-000000);
    text-align: left;
    margin-bottom: 10px;
    font: Bold 12px/15px NHaasGroteskTXW01-75Bd;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
`;

const PanelBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    height: calc(100vh - 110px);
    overflow-y: hidden;
    @media only screen and (max-width: 1400px) {
        height: calc(100vh - 135px);
    }
`;

const PanelFooter = styled.div`
    display: flex;
    padding: 10px 20px;
`;

const EndCustomerButtonWrapper = styled.div`
    display: flex;
    margin-bottom: auto;
    padding: 15px 0;
`;

/**
 * UserDetailDialog Component.
 * @param {*} props
 */
const UserDetailDialog = (props) => {
    /**
     * Disabled state of the submit button.
     */
    const [submitDisabled, setSubmitDisabled] = useState(false);

    /**
     * Use debtors from redux for caching
     */
    const debtors = useStoreState((state) => state.data.debtors);

    /**
     * A list of all currently assigned debtors.
     */
    const [assignedDebtors, setAssignedDebtors] = useState([]);

    /**
     * User notification message for the MessageBar.
     */
    const [userMessage, setUserMessage] = useState('');

    /**
     * Used by fluent ui for different message bar themes based on this type.
     */
    const [userMessageType, setUserMessageType] = useState(MessageBarType.info);

    /**
     * visible state for the busy indicator
     */
    const [isBusy, setIsBusy] = useState(false);

    /**
     * Whether the user invitation is busy or not.
     */
    const [isUserInvitationBusy, setIsUserInvitationBusy] = useState(false);

    /**
     * Whether the abort dialog is open or not.
     */
    const [isAbortDialogOpen, setIsAbortDialogOpen] = useState(false);

    /**
     * Whether the dialog to edit end customer numbers is open or not.
     */
    const [isEndCustomerDialogOpen, setIsEndCustomerDialogOpen] = useState(false);

    /**
     * State of the current assigned end customer numbers for the user.
     */
    const [endCustomerNumbers, setEndCustomerNumbers] = useState([]);

    /** Request abort controller. */
    const abortController = new AbortController();

    const cancelButtonStyles = {
        root: {
            border: 'unset',
            fontSize: '10px',
        },
    };

    const inviteButtonStyles = {
        root: {
            fontSize: '10px',
            backgroundColor: Colors.backgroundGray,
        },
        icon: {
            fontSize: 12,
        },
    };

    const saveButtonStyles = {
        root: {
            fontSize: '10px',
        },
    };

    const messageBarStyles = {
        root: {
            width: '100%',
        },
        icon: {
            color: Colors.errorRed,
        },
    };

    useEffect(() => {
        const fetchUserEndCustomerNumbers = async (id) => {
            setIsBusy(true);
            try {
                const fetchedEndCustomerNumbers = await fetchApiObject(`v1.0/User/EndCustomerNumbers/${id}`);
                setEndCustomerNumbers(fetchedEndCustomerNumbers);
            } catch (error) {
                console.error(error);
            } finally {
                setIsBusy(false);
            }
        };
        if (props.selectedUser != null) {
            fetchUserEndCustomerNumbers(props.selectedUser.id);
            let formattedData = formatDebtorData(props.selectedUser.debtors);
            setAssignedDebtors(formattedData);
            const filteredDebtors = debtors.filter((debtor) => {
                return !props.selectedUser.debtors.includes(debtor);
            });
            formattedData = formatDebtorData(filteredDebtors);
            if (props.selectedUser.endCustomerNumbers && props.selectedUser.endCustomerNumbers.length > 0) {
                setEndCustomerNumbers(props.selectedUser.endCustomerNumbers);
            }
        }
    }, [props.selectedUser, props.isHidden]);

    /**
     * Open the abort dialog when the input states are not default. If abort dialog must not be open, trigger close.
     */
    const openAbortDialog = () => {
        if (assignedDebtors.length !== props.selectedUser.debtors.length) {
            setIsAbortDialogOpen(true);
        } else {
            closePanel();
        }
    };

    /**
     * Reset the states and close the dialog.
     */
    const closePanel = () => {
        setIsAbortDialogOpen(false);
        setUserMessage('');
        setIsBusy(false);
        setAssignedDebtors([]);
        setUserMessageType(MessageBarType.info);
        setSubmitDisabled(false);
        setEndCustomerNumbers([]);
        props.close();
    };

    /**
     * Formats raw debtor data into table friendly objects.
     * @param {*} debtors
     */
    const formatDebtorData = (debtors) => {
        return debtors.map((debtor) => {
            return {
                id: debtor.id,
                identifier: debtor.identifier,
                companyName: debtor.companyName ? debtor.companyName : '',
                address: `${debtor.street ? debtor.street : ''} ${debtor.houseNumber ? debtor.houseNumber : ''} ${debtor.postalCode ? debtor.postalCode : ''} ${
                    debtor.city ? debtor.city : ''
                }`,
                dateCreated: toFormattedDate('de-DE', debtor.dateCreated),
            };
        });
    };

    /**
     * Handles the deselection of a debtor in the assigned debtors list.
     * @param {object} target the targeted debtor.
     */
    const deselectDebtor = (target) => {
        const updatedSelection = assignedDebtors.filter((debtor) => debtor.id !== target.id);
        setAssignedDebtors(updatedSelection);
    };

    /**
     * Handles the selection of a debtor in the debtors list.
     * @param {object} target The targeted debtor.
     */
    const selectDebtor = (target) => {
        setAssignedDebtors([...assignedDebtors, target]);
    };

    /**
     * Form submit.
     */
    const submit = async () => {
        setIsBusy(true);
        setSubmitDisabled(true);
        const user = props.selectedUser;
        const reqBody = {
            ...user,
            debtorIds: assignedDebtors.map((x) => x.id),
            endCustomerNumbers: endCustomerNumbers,
        };
        try {
            await postToApi('v1.0/User/Update', reqBody, abortController.signal);
            props.userUpdated();
            setUserMessageType(MessageBarType.success);
            setUserMessage(I18n.get().t('Update_User_SuccessMessage'));
            setTimeout(() => closePanel(), 2000);
        } catch (error) {
            setUserMessageType(MessageBarType.error);
            setUserMessage(I18n.get().t('Update_User_FailedMessage'));
            setSubmitDisabled(false);
            console.error(error);
        } finally {
            setIsBusy(false);
        }
    };

    /**
     * Send an invitation email to the currently selected user.
     */
    const inviteUser = async () => {
        setIsUserInvitationBusy(true);
        try {
            await fetchApiString(`v1.0/User/SendInvitationMail?emailAddress=${props.selectedUser.email}`);
            setUserMessageType(MessageBarType.success);
            setUserMessage(I18n.get().t('UserDetailDialog_InvitationSuccess'));
        } catch (error) {
            setUserMessageType(MessageBarType.error);
            setUserMessage(I18n.get().t('UserDetailDialog_InvitationFailed'));
            console.error(error);
        } finally {
            setIsUserInvitationBusy(false);
        }
    };

    /**
     * In memory list of the assigned debtors.
     */
    const inMemoryAssignedDedbtorList = useMemo(
        () => <DebtorList assignedDebtors={assignedDebtors} deselectDebtor={deselectDebtor} />,
        [assignedDebtors, setAssignedDebtors, deselectDebtor]
    );

    /**
     * Callback to close the end customer panel.
     */
    const closeEndCustomerPanel = () => {
        setIsEndCustomerDialogOpen(false);
    };

    /**
     * In memory list of all possible debtors.
     */
    const inMemorySearchableDebtorsList = useMemo(() => <DebtorSearch assignedDebtors={assignedDebtors} selectDebtor={selectDebtor} />, [assignedDebtors, selectDebtor]);

    /**
     * Body of the article timeline panel
     */
    const onRenderBody = () => (
        <PanelBody>
            <TopContainer>
                <UserDataContainer>
                    <Text>{I18n.get().t('EditUser_UserData_Title')}</Text>
                    <UserData>
                        <FlexRowSpace>
                            <FlexColumn>
                                <Label>{`${I18n.get().t('EditUser_UserData_Email_Label')}:`}</Label>
                                <Data>{props.selectedUser != null ? props.selectedUser.email : ''}</Data>
                            </FlexColumn>
                            <DefaultButton
                                disabled={!props.selectedUser || isUserInvitationBusy}
                                styles={inviteButtonStyles}
                                iconProps={!isUserInvitationBusy && { iconName: 'RepeatAll' }}
                                onClick={inviteUser}
                            >
                                {isUserInvitationBusy && (
                                    <ExportBusyWrapper>
                                        <BusySpinner busySize={SpinnerSize.small} isBusy={isUserInvitationBusy} />
                                    </ExportBusyWrapper>
                                )}
                                {isUserInvitationBusy ? I18n.get().t('UserDetailDialog_InvitationBusy') : I18n.get().t('UserDetailDialog_InviteUser')}
                            </DefaultButton>
                        </FlexRowSpace>
                        <Label>{`${I18n.get().t('EditUser_UserData_Company_Label')}:`}</Label>
                        <Data>{props.selectedUser != null ? props.selectedUser.company : ''}</Data>
                        <Label>{`${I18n.get().t('EditUser_UserData_Department_Label')}:`}</Label>
                        <Data>{props.selectedUser != null ? props.selectedUser.department : ''}</Data>
                    </UserData>
                    <EndCustomerButtonWrapper>
                        <OpenEndCustomerDialogButton callback={() => setIsEndCustomerDialogOpen(true)} />
                    </EndCustomerButtonWrapper>
                </UserDataContainer>
                {inMemoryAssignedDedbtorList}
            </TopContainer>
            <BottomContainer>{inMemorySearchableDebtorsList}</BottomContainer>
        </PanelBody>
    );

    /**
     * Footer content of the panel.
     */
    const onRenderFooterContent = () => (
        <PanelFooter>
            {userMessage !== '' && (
                <MessageBar styles={userMessageType === MessageBarType.error && messageBarStyles} isMultiline={false} messageBarType={userMessageType}>
                    {userMessage}
                </MessageBar>
            )}

            <FlexRowEnd>
                <BusySpinner isBusy={isBusy} />
                <DefaultButton styles={cancelButtonStyles} onClick={openAbortDialog} text={I18n.get().t('Edit_User_Cancle_Label')} />
                <PrimaryButton styles={saveButtonStyles} disabled={submitDisabled} onClick={submit} text={I18n.get().t('Edit_User_Submit_Label')} />
            </FlexRowEnd>
        </PanelFooter>
    );

    return (
        <>
            <EndCustomerDialog
                isOpen={isEndCustomerDialogOpen}
                closeCallback={closeEndCustomerPanel}
                endCustomerNumbers={endCustomerNumbers}
                setEndCustomerNumbers={setEndCustomerNumbers}
            />
            <Panel
                allowTouchBodyScroll
                isOpen={!props.isHidden && !isEndCustomerDialogOpen}
                isFooterAtBottom={true}
                onDismiss={openAbortDialog}
                type={PanelType.extraLarge}
                closeButtonAriaLabel="Schließen"
                headerText={I18n.get().t('EditUser_UserData_Title')}
                onRenderBody={onRenderBody}
                onRenderFooter={onRenderFooterContent}
                onOuterClick={() => {
                    // NOTE: This is needed to prevent the panel from closing when the user interacts with the dialog.
                }}
            />
            <BasicDialog
                hidden={!isAbortDialogOpen}
                title={I18n.get().t('AbortUserEditDialog_Headline')}
                text={I18n.get().t('AbortUserEditDialog_Text')}
                confirmCallback={() => closePanel()}
                cancelCallback={() => setIsAbortDialogOpen(false)}
            />
        </>
    );
};

export default UserDetailDialog;
