import React from 'react';
import styled from 'styled-components';
import { FontIcon } from 'office-ui-fabric-react';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    position: relative;
`;

const TextContainer = styled.div`
    position: absolute;
    top: 40px;
    min-width: max-content;
    @media only screen and (max-width: 1400px) {
        top: 30px;
    }
`;

const Circle = styled.div`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.progressColor};
`;

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

/**
 * takes the item text and if it es finished as prop and renders the progress dependent style
 * @param props
 */
export const ProgressItem = (props) => {
    const iconStyles = mergeStyles({
        textAlign: 'center',
        justifyContent: 'center',
        marginLeft: '1px',
        marginBottom: '2px',
    });

    return (
        <ItemContainer>
            <Circle progressColor={props.progressColor}>
                <IconContainer>{props.checked ? <FontIcon iconName="check-white" className={iconStyles} /> : ''}</IconContainer>
            </Circle>
            <TextContainer>{props.text}</TextContainer>
        </ItemContainer>
    );
};
