import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import I18n from '../../../helper/Localization';
import { PrimaryButton, getTheme } from 'office-ui-fabric-react';
import { Colors } from '../../../styles/Globals';
import Table from '../../Table';
import { postToApi } from '../../../helper/ApiHelper';
import { toFormattedDate } from '../../../helper/DateFormatHelper';
import { PaginationControls } from '../../PaginationControls';
import { BusySpinnerOverlay } from '../../busyIndicators/BusySpinnerOverlay';
import { CustomSearchBox } from '../../CustomSearchBox';

/**
 *  styled components
 */
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;

const DebtorSearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${Colors.backgroundGray};
    padding: 20px;
    border: 1px solid var(--unnamed-color-f0f0f0);
    border-radius: 4px;
    overflow: hidden;
`;

const DebtorSearchTable = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
`;

const Text = styled.p`
    color: var(--unnamed-color-000000);
    text-align: left;
    margin: 0px;
    font: Bold 12px/15px NHaasGroteskTXW01-75Bd;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
`;

const TableWrapper = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
    position: relative;
`;

const EmptyPageMassage = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

/**
 * Debtor search component
 */
const DebtorSearch = (props) => {
    /** Request abort controller. */
    const abortController = new AbortController();

    const [searchValue, setSearchValue] = useState('');

    /**
     * State of the currently fetched debtors.
     */
    const [debtors, setDebtors] = useState([]);

    /**
     * State of the currently filtered debtors.
     */
    const [filteredDebtors, setFilteredDebtors] = useState([]);

    /**
     * The maximum number of pages of the current fetched data.
     */
    const [maxPages, setMaxPages] = useState(1);

    /**
     * The current selected data page.
     */
    const [currentPage, setCurrentPage] = useState(1);

    /**
     * visible state for the busy indicator
     */
    const [isBusy, setIsBusy] = useState(false);

    /**
     * State that is used to trigger the search and therefore a new fetch.
     */
    const [searchToggle, setSearchToggle] = useState(false);

    /**
     * Reference to the previous search value to compare with the current search value.
     */
    const previousSearchValue = useRef('');

    /**
     * Handle the filter of already assigned debtors.
     */
    useEffect(() => {
        if (props.assignedDebtors && props.assignedDebtors.length > 0) {
            // Create shallow copy of all debtors.
            let tmpDebtors = [...debtors];
            // Filter out the already assigned debtors.
            tmpDebtors = tmpDebtors.filter((deb) => !props.assignedDebtors.some((assDebtor) => assDebtor.id === deb.id));
            // Set the state of the filtered debtors.
            setFilteredDebtors([...tmpDebtors]);
        } else {
            // If no debtors are assigned yet, the filtered are the same.
            setFilteredDebtors([...debtors]);
        }
    }, [props.assignedDebtors, debtors]);

    /**
     *  Mimics a ComponentDidMount lifecycle method
     */
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsBusy(true);
                const defaultReqBody = {
                    itemsPerPage: 25,
                    sortDirection: 1,
                    sortPropertyName: 'CompanyName',
                };
                let reqBody;
                if (searchValue && searchValue !== '') {
                    if (searchValue !== previousSearchValue.current) {
                        reqBody = {
                            filterText: searchValue.toLowerCase(),
                            pageToDeliver: 1,
                            ...defaultReqBody,
                        };
                    } else {
                        reqBody = {
                            filterText: searchValue.toLowerCase(),
                            pageToDeliver: currentPage,
                            ...defaultReqBody,
                        };
                    }
                } else {
                    reqBody = {
                        pageToDeliver: currentPage,
                        ...defaultReqBody,
                    };
                }
                const data = await postToApi('v1/Debtor/Paged', reqBody, abortController.signal);
                if (data) {
                    const formattedData = data.results.map((debtor) => {
                        const result = {
                            id: debtor.id,
                            identifier: debtor.identifier,
                            companyName: debtor.companyName ? debtor.companyName : '',
                            address: `${debtor.street ? debtor.street : ''} ${debtor.houseNumber ? debtor.houseNumber : ''} ${debtor.postalCode ? debtor.postalCode : ''} ${
                                debtor.city ? debtor.city : ''
                            }`,
                            dateCreated: toFormattedDate('de-DE', debtor.dateCreated),
                        };
                        return result;
                    });
                    setDebtors(formattedData);
                }
                setCurrentPage(data.page);
                setMaxPages(data.pagesAvailable);
            } catch (error) {
                setMaxPages(1);
                setCurrentPage(1);
                setDebtors([]);
            } finally {
                previousSearchValue.current = searchValue;
                setIsBusy(false);
            }
        };
        fetchData();
    }, [currentPage, setCurrentPage, searchToggle, setSearchToggle]);

    /**
     * fluent ui theme
     */
    const theme = getTheme();
    /**
     * fluent ui
     */
    const buttonStyles = {
        icon: {
            color: 'white',
            fontSize: 10,
        },
        root: {
            border: 0,
            fontSize: 10,
            borderRadius: '2px',
            boxShadow: '1px 2px 3px #0000003C',
            backgroundColor: theme.palette.themePrimary,
            width: 22,
            height: 22,
            marginRight: 10,
        },
        rootHovered: {
            backgroundColor: 'none',
        },

        rootPressed: {
            backgroundColor: 'none',
        },
    };

    const columns = [
        {
            Header: '',
            accessor: 'identifier',
            width: 60,
        },
        {
            Header: '',
            accessor: 'companyName',
            width: 120,
        },
        {
            Header: '',
            accessor: 'address',
            width: 120,
        },
        {
            Header: '',
            accessor: 'dateCreated',
            width: 80,
        },
        {
            Header: '',
            accessor: 'select',
            width: 40,
            Cell: (table) => {
                const debtor = table.row.original;
                return (
                    <ActionWrapper>
                        <PrimaryButton
                            text={I18n.get().t('Debtor_Search_AddAction')}
                            styles={buttonStyles}
                            title="Add"
                            ariaLabel="Add"
                            onClick={(event) => {
                                event.preventDefault();
                                props.selectDebtor(debtor);
                            }}
                        />
                    </ActionWrapper>
                );
            },
        },
    ];

    return (
        <DebtorSearchContainer>
            <Text>{I18n.get().t('Debtor_Search_Title')}</Text>
            <div>
                <CustomSearchBox
                    width={'100%'}
                    isBusy={isBusy}
                    placeholder={I18n.get().t('Debtor_Search_SearchBox_Placeholder')}
                    busyText={I18n.get().t('Placeholder_SearchBox_Busy')}
                    searchValue={props.searchValue}
                    updateSearchValue={(newValue) => setSearchValue(newValue)}
                    applySearch={() => {
                        setSearchToggle(!searchToggle);
                        setCurrentPage(1);
                    }}
                />
            </div>
            <DebtorSearchTable>
                <TableWrapper>
                    <BusySpinnerOverlay isBusy={isBusy} />
                    {filteredDebtors.length <= 0 && <EmptyPageMassage>{I18n.get().t('DebtorTable_EmptyPageMessage')}</EmptyPageMassage>}
                    {filteredDebtors.length >= 1 && <Table showHeader={false} columns={columns} data={filteredDebtors} disableSort={true} />}
                </TableWrapper>
                <PaginationControls
                    currentPage={currentPage}
                    maxPages={maxPages}
                    navigateBackwards={() => setCurrentPage(currentPage - 1)}
                    navigateToBegin={() => setCurrentPage(1)}
                    navigateToEnd={() => setCurrentPage(maxPages)}
                    navigateForward={() => setCurrentPage(currentPage + 1)}
                />
            </DebtorSearchTable>
        </DebtorSearchContainer>
    );
};

export default DebtorSearch;
