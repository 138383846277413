import React from 'react';
import styled from 'styled-components';
import { Overlay } from 'office-ui-fabric-react';
import { Colors } from '../styles/Globals';
import { logout } from '../helper/AuthHelper';
import I18n from '../helper/Localization';
const UserMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  position: absolute;
  right: 0;
  top: 50px;
  box-shadow: 1px 2px 6px ${Colors.boxShadow};
`;
const MenuItem = styled.div`
  display: flex;
  padding: 5px 60px 5px 20px;
  min-width: max-content;
  border-top: solid 1px ${Colors.borderGray};
  cursor: pointer;
  &:hover {
    background-color: ${Colors.hoverLightGray};
  }
`;
/**
 * The menu to provide user interactions.
 */
export const UserMenu = (props) => {
  return (
    <>
      {props.isVisible && (
        <Overlay>
          <UserMenuContainer>
            <MenuItem onClick={logout}>{I18n.get().t('UserMenu_Logout')}</MenuItem>
          </UserMenuContainer>
        </Overlay>
      )}
    </>
  );
};