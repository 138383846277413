import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { StoreProvider } from 'easy-peasy';
import Store from './store/Store';
import AppInitializer from './AppInitializer';
import { loadTheme } from '@fluentui/react';
import { initializeIcons } from '@uifabric/icons';
import { registerIcons } from 'office-ui-fabric-react/lib/Styling';

import InfoIcon from './assets/info-circle-solid.svg';
import CheckIcon from './assets/check-solid.svg';
import ContextIcon from './assets/ellipsis-v-solid.svg';
import EditIcon from './assets/pen-solid.svg';
import DisableWhiteIcon from './assets/disable-white.svg';
import EnableIcon from './assets/circle-notch-solid.svg';
import HomeIcon from './assets/home-solid.svg';
import UsersIcon from './assets/users-solid.svg';
import FolderIcon from './assets/folder-open-solid.svg';
import ResultIcon from './assets/business-and-finance.svg';
import PlusIcon from './assets/times-solid.svg';
import ChevronIcon from './assets/menu-arrow.svg';
import FailedIcon from './assets/failed.svg';
import SucceededIcon from './assets/succeeded.svg';
import PendingIcon from './assets/pending.svg';
import UserIcon from './assets/user-alt-solid.svg';
import DownloadIcon from './assets/download-solid.svg';
import DisableIcon from './assets/disable.svg';
import DownloadWhiteIcon from './assets/download-solid-white.svg';
import SearchIcon from './assets/search-solid.svg';
import LeftOutlined from './assets/hörtest-avatar-04.svg';
import LeftFilled from './assets/hörtest-avatar-02.svg';
import RightOutlined from './assets/hörtest-avatar-05.svg';
import RightFilled from './assets/hörtest-avatar-03.svg';
import WindowsStepOne from './assets/system-volume_01.svg';
import WindowsStepTwo from './assets/system-volume-04.svg';
import MacStepOne from './assets/system-volume-02.svg';
import MacStepTwo from './assets/system-volume-03.svg';
import MacStepThree from './assets/system-volume-05.svg';
import MacStepFour from './assets/system-volume-06.svg';
import EnableSolidIcon from './assets/enable-solid.svg';
import ArrowLeftWhiteIcon from './assets/Arrow-left-white.svg';
import ArrowLeftBackIcon from './assets/Arrow-left-black.svg';
import ArrowLeftGrayIcon from './assets/Arrow-left-gray.svg';
import CheckWhiteIcon from './assets/check-white.svg';
import SliderBubblePrimary from './assets/slider-bubble-primary-03.svg';
import SliderBubbleWhite from './assets/slider-bubble-white.svg';
import MusicIcon from './assets/music.svg';
import CloseIcon from './assets/close.svg';
import IPadStepOne from './assets/ipad-system-volume-01.svg';
import IPadStepTwo from './assets/ipad-system-volume-02.svg';

const rootElement = document.getElementById('root');

// Initialize icons.
initializeIcons();
registerIcons({
    icons: {
        'ipad-step-one': <img src={IPadStepOne} alt=""></img>,
        'ipad-step-two': <img src={IPadStepTwo} alt=""></img>,
        'close-icon': <img src={CloseIcon} alt=""></img>,
        'music-icon': <img src={MusicIcon} alt=""></img>,
        'arrow-left-white': <img src={ArrowLeftWhiteIcon} alt=""></img>,
        'arrow-left-black': <img src={ArrowLeftBackIcon} alt=""></img>,
        'arrow-left-gray': <img src={ArrowLeftGrayIcon} alt=""></img>,
        'check-white': <img src={CheckWhiteIcon} alt=""></img>,
        'slider-bubble-primary': <img src={SliderBubblePrimary} alt=""></img>,
        'slider-bubble-white': <img src={SliderBubbleWhite} alt=""></img>,
        'pen-solid-svg': <img src={EditIcon} alt=""></img>,
        'disable-white-svg': <img src={DisableWhiteIcon} alt=""></img>,
        'check-solid-svg': <img src={CheckIcon} alt=""></img>,
        'circle-notch-solid-svg': <img src={EnableIcon} alt=""></img>,
        'info-circle-solid-svg': <img src={InfoIcon} alt=""></img>,
        'home-svg': <img src={HomeIcon} alt=""></img>,
        'users-svg': <img src={UsersIcon} alt=""></img>,
        'folder-svg': <img src={FolderIcon} alt=""></img>,
        'result-svg': <img src={ResultIcon} alt=""></img>,
        'plus-svg': <img src={PlusIcon} alt=""></img>,
        'chevron-svg': <img src={ChevronIcon} alt=""></img>,
        'ellipsis-v-solid-svg': <img src={ContextIcon} alt=""></img>,
        'failed-svg': <img src={FailedIcon} alt=""></img>,
        'succeeded-svg': <img src={SucceededIcon} alt=""></img>,
        'pending-svg': <img src={PendingIcon} alt=""></img>,
        'user-alt-solid-svg': <img src={UserIcon} alt=""></img>,
        'download-solid-svg': <img src={DownloadIcon} alt=""></img>,
        'disable-svg': <img src={DisableIcon} alt=""></img>,
        'download-solid-white-svg': <img src={DownloadWhiteIcon} alt=""></img>,
        'search-svg': <img src={SearchIcon} alt=""></img>,
        'active-left-outlined': <img src={LeftOutlined} alt=""></img>,
        'active-left-filled': <img src={LeftFilled} alt=""></img>,
        'active-right-outlined': <img src={RightOutlined} alt=""></img>,
        'active-right-filled': <img src={RightFilled} alt=""></img>,
        'windows-step-one': <img src={WindowsStepOne} alt=""></img>,
        'windows-step-two': <img src={WindowsStepTwo} alt=""></img>,
        'mac-step-one': <img src={MacStepOne} alt=""></img>,
        'mac-step-two': <img src={MacStepTwo} alt=""></img>,
        'mac-step-three': <img src={MacStepThree} alt=""></img>,
        'mac-step-four': <img src={MacStepFour} alt=""></img>,
        'enable-solid-svg': <img src={EnableSolidIcon} alt=""></img>,
    },
});

// Load fluent ui theme.
loadTheme({
    palette: {
        themePrimary: '#E51046',
        themeLighterAlt: '#fff9f5',
        themeLighter: '#ffe7d6',
        themeLight: '#ef2054',
        themeTertiary: '#eb0f46',
        themeSecondary: '#d90f42',
        themeDarkAlt: '#ce0e3e',
        themeDark: '#c00d3a',
        themeDarker: '#b70c37',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#c2c2c2',
        neutralSecondary: '#858585',
        neutralPrimaryAlt: '#4b4b4b',
        neutralPrimary: '#333333',
        neutralDark: '#272727',
        black: '#1d1d1d',
        white: '#ffffff',
        red: '#E50F46',
        blue: '#037399',
        green: '#12B361',
        lightGrey: '#666666',
        transparentGrey: '#000',
    },
});

ReactDOM.render(
    <StoreProvider store={Store}>
        <AppInitializer />
    </StoreProvider>,
    rootElement
);

// Register the service worker.
serviceWorkerRegistration.register();
