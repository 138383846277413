import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import I18n from '../../../helper/Localization';
import { PrimaryButton, DefaultButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import { BasicDialog } from '../BasicDialog';
import { BusySpinner } from '../../busyIndicators/BusySpinner';
import { Colors } from '../../../styles/Globals';
import { getTheme } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { fetchApiObject, postToApi } from '../../../helper/ApiHelper';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { ComboBox } from '@fluentui/react';
import { isUserAccountManager, isUserAdministrator } from '../../../helper/RoleHelper';
import { useStoreState } from 'easy-peasy';

const FlexRow = styled.div`
    display: flex;
    margin-left: auto;
    max-width: max-content;
`;

const PanelBody = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 15px 24px;
`;

const ComboBoxWrapper = styled.div`
    min-height: 75px;
`;

const MessageWrapper = styled.div`
    display: flex;
    flex: 1;
    visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
`;

const InputArea = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: max-content;
    min-height: max-content;
    background-color: ${Colors.backgroundGray};
    padding: 10px 20px 20px 20px;
    margin-bottom: 10px;
`;

const InputAreaHeadline = styled.div`
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: bold;
    color: ${(props) => props.fontColor};
`;

const PanelFooter = styled.div`
    display: flex;
    padding: 10px 20px;
`;

const DeleteButtonWrapper = styled.div`
    position: absolute;
    top: 1px;
    right: 1px;
`;

/**
 * Dialog component to manually create an order with debtor and positions.
 */
export const ManualOrderDialog = (props) => {
    const theme = getTheme();

    const userRoles = useStoreState((state) => state.user.roles);

    /**
     * Whether the abort dialog is open or not.
     */
    const [isAbortDialogOpen, setIsAbortDialogOpen] = useState(false);

    /**
     * Whether the confirm dialog is open or not.
     */
    const [isConfirmSaveDialogOpen, setIsConfirmSaveDialogOpen] = useState(false);

    /**
     * Whether this component is busy or not.
     */
    const [isBusy, setIsBusy] = useState(false);

    /**
     * All available debtors to select.
     */
    const [availableDebtors, setAvailableDebtors] = useState([]);

    /**
     * The available end customer numbers to select.
     */
    const [availableEndCustomerNumbers, setAvailableEndCustomerNumbers] = useState([]);

    /**
     * State of the debtor number user input.
     */
    const [selectedDebtor, setSelectedDebtor] = useState(null);

    /**
     * The selected end customer number.
     */
    const [selectedEndCustomerNumber, setSelectedEndCustomerNumber] = useState(null);

    /**
     * State of the order delivery invoice number user input.
     */
    const [deliveryInvoiceNumber, setDeliveryInvoiceNumber] = useState('');

    /**
     * State of the order position.
     */
    const [orderPositions, setOrderPositions] = useState([{ serialNumber: '', wearer: '', position: 1 }]);

    /**
     * Error message for the MessageBar
     */
    const [errorMessage, setErrorMessage] = useState('');

    /**
     * Success message for the MessageBar
     */
    const [successMessage, setSuccessMessage] = useState('');

    /**
     * Reference whether the user has clicked the dropdown yet or not.
     */
    const [hasUserClickedDebtorDropdown, setHasUserClickedDebtorDropdown] = useState(false);

    /**
     * Reference whether the user has clicked the dropdown yet or not.
     */
    const [hasUserClickedEndCustomerNumberDropdown, setHasUserClickedEndCustomerNumberDropdown] = useState(false);

    /**
     * Fetch all debtors the current user is authorized to select, and the users assigned end cutomer nubmers.
     */
    useEffect(() => {
        if (props.isOpen) {
            const fetchAvailableDebtors = async () => {
                try {
                    const allDebtors = await fetchApiObject('v1.0/Debtor/AllAuthorized');
                    setAvailableDebtors(allDebtors);
                } catch (error) {
                    console.error(error);
                }
            };
            const fetchUserEndCustomerNumbers = async () => {
                try {
                    const allEndCustomerNumbers = await fetchApiObject('v1.0/User/EndCustomerNumbers');
                    if (allEndCustomerNumbers) {
                        setAvailableEndCustomerNumbers(allEndCustomerNumbers);
                    }
                } catch (error) {
                    console.error(error);
                }
            };
            setIsBusy(true);
            const debtorPromise = fetchAvailableDebtors();
            const endCustomerNumberPromise = fetchUserEndCustomerNumbers();
            Promise.all([debtorPromise, endCustomerNumberPromise]).finally(() => setIsBusy(false));
        }
    }, [props.isOpen]);

    /**
     * Styles of the cancel button.
     */
    const cancleButtonStyles = {
        root: {
            border: 'unset',
            fontSize: '10px',
            marginLeft: '15px',
        },
    };

    /**
     * Styles of the save button.
     */
    const saveButtonStyles = {
        root: {
            fontSize: '10px',
            marginLeft: '15px',
        },
    };

    /**
     * Styles of the input fields.
     */
    const textFieldStyles = {
        root: {
            fontSize: 10,
        },
        field: {
            height: 23,
            paddingLeft: 20,
        },
        fieldGroup: {
            height: 24,
            border: `0.5px solid ${Colors.borderGray};`,
        },
        errorMessage: {
            fontSize: 10,
        },
    };

    /**
     * Styles for the required textfields.
     */
    const requiredTextFieldStyles = {
        ...textFieldStyles,
        root: {
            fontSize: 10,
            minHeight: 75,
        },
    };

    /**
     * Props for the plus icon.
     */
    const plusIconProps = {
        iconName: 'Add',
    };

    /**
     * Props of the close icon.
     */
    const closeIconProps = {
        iconName: 'ChromeClose',
    };

    /**
     * Styles of the close button.
     */
    const closeButtonStyles = {
        root: {
            height: 24,
            width: 24,
        },
        icon: {
            color: theme.palette.black,
            fontSize: 10,
        },
    };

    /**
     * Styles of the combo box menu.
     */
    const comboBoxStyles = {
        root: {
            height: 23,
        },
        errorMessage: {
            fontSize: 10,
        },
    };

    /**
     * Reset all states and close the panel.
     */
    const closePanel = () => {
        setHasUserClickedDebtorDropdown(false);
        setAvailableDebtors([]);
        setSelectedDebtor(null);
        setOrderPositions([{ serialNumber: '', wearer: '', position: 1 }]);
        setErrorMessage('');
        setSuccessMessage('');
        setIsAbortDialogOpen(false);
        setIsConfirmSaveDialogOpen(false);
        setSelectedEndCustomerNumber(null);
        setAvailableEndCustomerNumbers(null);
        setHasUserClickedEndCustomerNumberDropdown(false);
        props.toggleHideDialog();
    };

    /**
     * Callback that is executed when the user tries to leave the panel.
     */
    const onDismiss = () => {
        // Check if user has unsaved changes.
        if (
            selectedEndCustomerNumber !== null ||
            selectedDebtor !== null ||
            deliveryInvoiceNumber !== '' ||
            orderPositions.length !== 1 ||
            orderPositions[0].serialNumber !== '' ||
            orderPositions[0].wearer !== ''
        ) {
            setIsAbortDialogOpen(true);
        } else {
            closePanel();
        }
    };

    /**
     * Submit the new created manual order to the backend.
     */
    const submitManualOrder = async () => {
        setSuccessMessage('');
        setErrorMessage('');
        setIsBusy(true);
        const manualOrderRequestBody = {
            debtor: selectedDebtor,
            deliveryInvoiceNumber: deliveryInvoiceNumber,
            isManualOrder: true,
            positions: [...orderPositions],
            endCustomerNumber: selectedEndCustomerNumber,
        };
        try {
            await postToApi('v1.0/Order/Manual', manualOrderRequestBody);
            setSuccessMessage(I18n.get().t('ManualOrderDialog_SuccessMessage'));
            setTimeout(() => closePanel(), 2000);
        } catch (error) {
            console.error(error);
            setErrorMessage(I18n.get().t('ManualOrderDialog_ErrorMessage'));
        } finally {
            setIsBusy(false);
        }
    };

    /**
     * Check if the value is a valid debtor number.
     * @param {*} value The value to check.
     * @returns True if the number is valid, false if not.
     */
    const validateNotEmpty = (value) => {
        return value && value !== '';
    };

    /**
     * Get error message for invalid delivery invoice number.
     */
    const onGetErrorOrderDeliveryInvoiceNumber = (value) => {
        if (!validateNotEmpty(value)) {
            return I18n.get().t('ManualOrderDialog_Order_DeliveryInvoiceNumberError');
        }
        return '';
    };

    /**
     * Get error message for invalid wearer.
     */
    const onGetErrorWearer = (value) => {
        if (!validateNotEmpty(value)) {
            return I18n.get().t('ManualOrderDialog_OrderPosition_WearerError');
        }
        return '';
    };

    /**
     * Determine if the save button is disabled or not.
     */
    const isSaveButtonDisabled = () => {
        if (
            validateNotEmpty(selectedDebtor) &&
            (validateNotEmpty(selectedEndCustomerNumber) || availableEndCustomerNumbers.length <= 0) &&
            validateNotEmpty(deliveryInvoiceNumber) &&
            orderPositions.length > 0
        ) {
            for (let i = 0; i < orderPositions.length; i++) {
                if (!orderPositions[i].wearer || orderPositions[i].wearer === '') {
                    return true;
                }
            }
            return false;
        }
        return true;
    };

    /**
     * Body of the panel.
     */
    const onRenderBody = () => (
        <PanelBody>
            <InputAreaHeadline fontColor={theme.palette.black}>{I18n.get().t('ManualOrderDialog_Debtor_Headline')}</InputAreaHeadline>
            <InputArea>
                <ComboBoxWrapper>
                    <ComboBox
                        allowFreeform={true}
                        disabled={isBusy}
                        styles={comboBoxStyles}
                        label={I18n.get().t('ManualOrderDialog_Debtor_NumberLabel')}
                        options={availableDebtors.map((debtor) => ({ key: debtor.identifier, text: `${debtor.identifier} ${debtor.companyName && `- ${debtor.companyName}`}` }))}
                        errorMessage={selectedDebtor === null && hasUserClickedDebtorDropdown === true ? I18n.get().t('ManualOrderDialog_Debtor_NumberError') : ''}
                        onClick={() => setHasUserClickedDebtorDropdown(true)}
                        text={selectedDebtor ? selectedDebtor.identifier : ''}
                        onChange={(event, option) => {
                            if (option) {
                                const tmpDebtor = availableDebtors.find((d) => d.identifier === option.key);
                                setSelectedDebtor(tmpDebtor);
                            }
                        }}
                    />
                </ComboBoxWrapper>
            </InputArea>
            {availableEndCustomerNumbers && availableEndCustomerNumbers.length > 0 ? (
                <InputArea>
                    <ComboBoxWrapper>
                        <ComboBox
                            allowFreeform={true}
                            disabled={isBusy}
                            styles={comboBoxStyles}
                            label={I18n.get().t('ManualOrderDialog_Order_EndCustomerNumber')}
                            options={availableEndCustomerNumbers.map((endCustomerNumber) => ({ key: endCustomerNumber, text: endCustomerNumber }))}
                            errorMessage={
                                selectedEndCustomerNumber === null && hasUserClickedEndCustomerNumberDropdown === true
                                    ? I18n.get().t('ManualOrderDialog_Order_EndCustomerNumberError')
                                    : ''
                            }
                            onClick={() => setHasUserClickedEndCustomerNumberDropdown(true)}
                            text={selectedEndCustomerNumber ? selectedEndCustomerNumber : ''}
                            onChange={(event, option) => {
                                if (option) {
                                    setSelectedEndCustomerNumber(option.key);
                                }
                            }}
                        />
                    </ComboBoxWrapper>
                </InputArea>
            ) : isUserAdministrator(userRoles) || isUserAccountManager(userRoles) ? (
                <InputArea>
                    <TextField
                        disabled={isBusy}
                        styles={textFieldStyles}
                        label={I18n.get().t('ManualOrderDialog_Order_EndCustomerNumber')}
                        placeholder=""
                        onChange={(event, newValue) => {
                            if (newValue || newValue === '') {
                                setSelectedEndCustomerNumber(newValue);
                            }
                        }}
                    />
                </InputArea>
            ) : (
                <></>
            )}
            <InputAreaHeadline fontColor={theme.palette.black}>{I18n.get().t('ManualOrderDialog_Order_Headline')}</InputAreaHeadline>
            <InputArea>
                <TextField
                    disabled={isBusy}
                    styles={requiredTextFieldStyles}
                    label={I18n.get().t('ManualOrderDialog_Order_DeliveryInvoiceNumber')}
                    validateOnFocusOut
                    validateOnLoad={false}
                    onGetErrorMessage={onGetErrorOrderDeliveryInvoiceNumber}
                    placeholder=""
                    onChange={(event, newValue) => {
                        if (newValue || newValue === '') {
                            setDeliveryInvoiceNumber(newValue);
                        }
                    }}
                />
            </InputArea>
            <InputAreaHeadline fontColor={theme.palette.black}>{I18n.get().t('ManualOrderDialog_OrderPositions_Headline')}</InputAreaHeadline>
            {orderPositions &&
                orderPositions.map((orderPosition, i) => {
                    // Determine the order position based on index.
                    orderPosition.position = i + 1;
                    return (
                        <InputArea key={i}>
                            <DeleteButtonWrapper>
                                <IconButton
                                    disabled={isBusy}
                                    styles={closeButtonStyles}
                                    iconProps={closeIconProps}
                                    onClick={() => {
                                        // Create shallow copy of all order positions.
                                        const tmpOrderPositions = [...orderPositions];
                                        // Remove current element from order positions.
                                        tmpOrderPositions.splice(i, 1);
                                        // Update the state.
                                        setOrderPositions([...tmpOrderPositions]);
                                    }}
                                />
                            </DeleteButtonWrapper>
                            <TextField
                                disabled={isBusy}
                                styles={textFieldStyles}
                                label={I18n.get().t('ManualOrderDialog_OrderPosition_SerialNumber')}
                                placeholder=""
                                value={orderPosition.serialNumber}
                                onChange={(event, newValue) => {
                                    if (newValue || newValue === '') {
                                        orderPosition.serialNumber = newValue;
                                        setOrderPositions([...orderPositions]);
                                    }
                                }}
                            />
                            <TextField
                                disabled={isBusy}
                                styles={requiredTextFieldStyles}
                                label={I18n.get().t('ManualOrderDialog_OrderPosition_Wearer')}
                                validateOnFocusOut
                                validateOnLoad={false}
                                onGetErrorMessage={onGetErrorWearer}
                                placeholder=""
                                value={orderPosition.wearer}
                                onChange={(event, newValue) => {
                                    if (newValue || newValue === '') {
                                        orderPosition.wearer = newValue;
                                        setOrderPositions([...orderPositions]);
                                    }
                                }}
                            />
                        </InputArea>
                    );
                })}
            <DefaultButton
                disabled={isBusy}
                iconProps={plusIconProps}
                text={I18n.get().t('ManualOrderDialog_OrderPosition_AddNew')}
                onClick={() => {
                    // Create shallow copy of all order positions.
                    const tmpOrderPositions = [...orderPositions];
                    // Add an empty order position.
                    tmpOrderPositions.push({ serialNumber: '', wearer: '' });
                    // Update the state.
                    setOrderPositions([...tmpOrderPositions]);
                }}
            />
        </PanelBody>
    );

    /**
     * Footer of the panel.
     */
    const onRenderFooter = () => (
        <PanelFooter>
            <MessageWrapper hidden={errorMessage === '' && successMessage === ''}>
                <MessageBar isMultiline={false} messageBarType={successMessage !== '' ? MessageBarType.success : MessageBarType.error}>
                    {successMessage !== '' ? successMessage : errorMessage}
                </MessageBar>
            </MessageWrapper>
            <FlexRow>
                <BusySpinner isBusy={isBusy} />
                <DefaultButton styles={cancleButtonStyles} disabled={isBusy} onClick={onDismiss} text={I18n.get().t('ManualOrderDialog_Cancel')} />
                <PrimaryButton
                    styles={saveButtonStyles}
                    disabled={isSaveButtonDisabled() || isBusy}
                    onClick={() => setIsConfirmSaveDialogOpen(true)}
                    text={I18n.get().t('ManualOrderDialog_Save')}
                />
            </FlexRow>
        </PanelFooter>
    );

    return (
        <>
            <Panel
                allowTouchBodyScroll
                headerText={I18n.get().t('ManualOrderDialog_HeaderText')}
                closeButtonAriaLabel={I18n.get().t('ManualOrderDialog_Close')}
                type={PanelType.medium}
                isOpen={props.isOpen}
                onDismiss={onDismiss}
                onRenderBody={onRenderBody}
                onRenderFooter={onRenderFooter}
                isFooterAtBottom
                onOuterClick={() => {
                    // NOTE: This is needed to prevent the panel from closing when the user interacts with the dialog.
                }}
            />
            <BasicDialog
                hidden={!isAbortDialogOpen}
                title={I18n.get().t('AbortManualOrder_Headline')}
                text={I18n.get().t('AbortManualOrder_Text')}
                confirmCallback={closePanel}
                cancelCallback={() => setIsAbortDialogOpen(false)}
            />
            <BasicDialog
                hidden={!isConfirmSaveDialogOpen}
                title={I18n.get().t('ConfirmManualOrder_Headline')}
                text={I18n.get().t('ConfirmManualOrder_Text')}
                confirmCallback={() => {
                    setIsConfirmSaveDialogOpen(false);
                    submitManualOrder();
                }}
                cancelCallback={() => setIsConfirmSaveDialogOpen(false)}
            />
        </>
    );
};
