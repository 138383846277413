import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

/** Localization class initiating i18n. */
export default class Localization {
  /** i18n instance. */
  i18nInstance;

  /** Promise for the loading i18next instance. */
  initPromise;

  /** Singleton instance. */
  static instance;

  /**
   * Loads the i18n requested languages from the localization api.
   *
   * @param requestedLanguage The ISO-code for the language to load.
   * @param callback The callback to execute when the languages are returned from the api.
   * @param options ajax options.
   * @param payload the requested language.
   */
  loadLocales = async (options, requestedLanguage, payload, callback) => {
    try {
      if(!navigator.onLine) {
        const result = localStorage.getItem('localization');
        callback(null, { status: 200, data: JSON.parse(result) });
        return;
      }
      if (requestedLanguage.toLowerCase() !== this.options.lng.toLowerCase()) {
        // do nothing when a language is requested, that isn't the one the user selected
        callback(null, { status: 200 });
        return;
      }
      const localeResult = await fetch(`api/Localization/Get?culture=${this.options.lng}`);
      const locale = await localeResult.json();
      localStorage.setItem('localization', JSON.stringify(locale));
      callback(null, { status: 200, data: locale });
    } catch (error) {
      console.error(`Failed language: ${requestedLanguage}. Error: ${error.message}`);
      callback(error, { status: 404 });
    }
  };

  /** I18n Options. */
  options = {
    backend: {
      request: this.loadLocales,
      allowMultiLoading: false,
      requestOptions: {
        mode: 'cors',
        credentials: 'same-origin',
        cache: 'default'
      },
      loadPath: '{{lng}}',
      parse: (data) => data
    },
    cache: {
      enabled: true
    },
    debug: false,
    defaultNS: 'common',
    initImmediate: false,
    interpolation: {
      escapeValue: false
    },
    ns: ['common'],
    react: {
      nsMode: 'default',
      wait: true
    }
  };

  constructor() {
    this.options.lng = 'de';
    this.initPromise = i18n.use(HttpApi).use(initReactI18next).init(this.options);
    this.initPromise.then(() => (this.i18nInstance = i18n));
  }

  /** Retrieves the single i18n instance. */
  static get() {
    const inst = this.getInstance();
    while (inst.i18nInstance == null) {
      // wait for i18n to load...
    }
    return inst.i18nInstance;
  }

  /** Retrieves the promise for the i18n instance. */
  static getPromise() {
    return this.getInstance().initPromise;
  }

  /** Retrieves the singleton instance. */
  static getInstance() {
    return this.instance || (this.instance = new this());
  }
}
