import React, { Component } from 'react';
import styled from 'styled-components';
import I18n from '../../helper/Localization';
import { DefaultButton, FontIcon, getTheme, PrimaryButton } from 'office-ui-fabric-react';
import { playSound } from '../../helper/SoundHelper';
import { mergeStyles, mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';

const TestExecutionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const LeftContainer = styled.div`
  display: flex;
  min-width: 440px;
  flex-direction: column;
  color: white;
  flex: 1;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 2;
`;

const ExplanationWrapper = styled.div`
  padding: 55px 70px 0 47px;
  @media only screen and (max-width: 1400px) {
    padding: 25px 40px 0 47px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 40px;
  @media only screen and (max-width: 1400px) {
    font-size: 23px;
  }
`;

const ExplanationContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  font-size: 18px;
  @media only screen and (max-width: 1400px) {
    font-size: 14px;
    padding-top: 10px;
  }
`;

const SoundIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 450px;
  width: 60%;
  min-height: 45px;
  height: 45px;
  @media only screen and (max-height: 680px) {
    min-width: 350px;
  }
`;

const SoundIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.backgroundColor};
  margin-top: 25px;
  justify-content: center;
  align-items: center;
  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
`;

const ButtonContainer = styled.div`
  margin-top: auto;
  padding: 30px 48px 47px 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: max-content;
  @media only screen and (max-width: 1500px) {
    padding: 30px 15px 27px 15px;
  }
`;

const UserIconContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: auto;
  padding: 10px;
  min-width: 430px;
  @media only screen and (max-width: 1400px) {
    min-width: 350px;
  }
`;

const MarginBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
`;

const CountdownOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: 'none';
  width: 100%;
  height: 100%;
  z-index: 10;
  top: -50px;
  left: 0;
  position: absolute;
  font-size: 103px;
  font-weight: bold;
  color: ${(props) => props.fontColor};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

const StyledList = styled.ol`
  padding-left: 15px;
`;

/**
 * the default gain value
 */
const defaultGainValue = 0.0125;
/**
 * the default frequency in herz
 */
const defaultFrequency = 500;
/**
 * the default length of a sound in seconds
 */
const defaultSoundLength = 1.5;
/**
 * the step size to decrease the current gain value
 */
const lowerGainDivisor = 2;

/**
 * an offset in seconds before the tone is played.
 */
const offsetDuration = 3;

/**
 * view component that displays the test execution for left or right ear with or without hearing protection
 */
export class TestExecutionView extends Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.state = {
      ear: 'left',
      isTestActive: false,
      withHearingProtection: false,
      currentGainValue: defaultGainValue,
      isSoundPlaying: false,
      isCountdownRunning: false,
      countdownValue: 0
    };
  }

  theme = getTheme();

  soundIconClass = mergeStyles({
    fontSize: 32,
    height: 50,
    width: 50,
    margin: '0 auto',
    paddingLeft: 8
  });
  classNames = mergeStyleSets({
    whiteIcon: [{ color: this.theme.palette.white }, this.soundIconClass]
  });

  commonButtonStyles = {
    root: {
      borderRadius: '4px',
      height: '39px',
      minHeight: '39px',
      fontSize: '15px'
    },
    rootHovered: {
      backgroundColor: 'none',
      color: 'none'
    },
    rootPressed: {
      backgroundColor: 'none',
      color: 'none'
    }
  };

  beginButtonStyles = {
    ...this.commonButtonStyles,
    root: {
      ...this.commonButtonStyles.root,
      color: 'white',
      border: '1px solid white',
      minWidth: '195px',
      backgroundColor: this.theme.palette.white
    }
  };

  toneButtonStyles = {
    ...this.commonButtonStyles,
    root: {
      ...this.commonButtonStyles.root,
      boxShadow: '1px 2px 3px #00000079',
      border: 0,
      minWidth: '180px'
    }
  };

  abortButtonStyles = {
    ...this.commonButtonStyles,
    root: {
      ...this.commonButtonStyles.root,
      border: 0,
      color: this.theme.palette.black,
      fontWeight: 'bold',
      minWidth: '195px'
    }
  };

  previousSoundButtonStyles = {
    ...this.commonButtonStyles,
    root: {
      ...this.commonButtonStyles.root,
      border: `1px solid ${this.theme.palette.black}`,
      color: this.theme.palette.black,
      fontWeight: 'bold',
      minWidth: '100%'
    },
    icon: {
      marginBottom: 3
    }
  };

  previousTestButtonStylesLeft = {
    ...this.commonButtonStyles,
    root: {
      ...this.commonButtonStyles.root,
      border: `1px solid ${this.theme.palette.white}`,
      color: this.theme.palette.white,
      fontWeight: 'bold',
      minWidth: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.0)'
    },
    icon: {
      marginBottom: 3
    }
  };

  previousTestButtonStylesTestActive = {
    ...this.commonButtonStyles,
    root: {
      ...this.commonButtonStyles.root,
      border: `1px solid ${this.theme.palette.black}`,
      color: this.theme.palette.black,
      fontWeight: 'bold',
      minWidth: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.0)'
    },
    icon: {
      marginBottom: 3
    }
  };

  /**
   * switch the current hearing test state to determine which heading is displayed
   */
  switchHeadline = () => {
    if (this.state.ear === 'left' && this.state.withHearingProtection) {
      return I18n.get().t('Test_Execution_LeftWithProtection');
    } else if (this.state.ear === 'left' && !this.state.withHearingProtection) {
      return I18n.get().t('Test_Execution_LeftWithoutProtection');
    } else if (this.state.ear === 'right' && this.state.withHearingProtection) {
      return I18n.get().t('Test_Execution_RightWithProtection');
    } else if (this.state.ear === 'right' && !this.state.withHearingProtection) {
      return I18n.get().t('Test_Execution_RightWithoutProtection');
    }
  };

  /**
   * calculates the gain ratio based on the max gain and the gain value at which the user can't hear the sound anymore
   * @param baseValue the max gain value
   * @param value the gain value at which the user can't hear the sound anymore
   */
  calcGainRatio = (baseValue, value) => {
    return value / baseValue;
  };

  /**
   * save the test result, reset the values and continue with the next test
   */
  finishCurrentTest = () => {
    // save result in test state
    this.props.dbHandler(this.state.ear, this.state.withHearingProtection, this.calcGainRatio(defaultGainValue, this.state.currentGainValue), () => this.continueWithNextTest());
    // change test status to finished
    this.setState({ isTestActive: false });
  };

  /**
   * helper function that takes the current test state and knows next test
   */
  continueWithNextTest = () => {
    this.setState({ currentGainValue: defaultGainValue });
    if (this.state.ear === 'left') {
      this.setState({ ear: 'right' });
    } else if (this.state.ear === 'right' && !this.state.withHearingProtection) {
      this.props.continue();
      this.setState({ ear: 'left', withHearingProtection: true });
    }
  };

  /**
   * function that is used when the "start test" button is pressed
   */
  callbackStartTest = () => {
    // set the test status to active
    this.setState({ isTestActive: true });
    // play the first sound with initial gain after a three seconds countdown
    this.playWithDelay();
  };

  /**
   * Plays the test tone with a delay in form of a countdown.
   */
  playWithDelay = () => {
    playSound(this.state.ear, this.state.currentGainValue, defaultFrequency, defaultSoundLength, offsetDuration, () => this.setState({ isSoundPlaying: false }));
    this.setState({ countdownValue: offsetDuration, isCountdownRunning: true });
    this.interval = setInterval(() => {
      this.setState({ countdownValue: this.state.countdownValue - 1 }, () => {
        if (this.state.countdownValue <= 0) {
          clearInterval(this.interval);
          this.setState({ isCountdownRunning: false, isSoundPlaying: true });
        }
      });
    }, 1000);
  };

  /**
   * function that is used when the "tone heard" button is pressed
   */
  callbackToneHeard = () => {
    // lower the gain by one step and play a sound afterwards.
    this.setState({ currentGainValue: this.state.currentGainValue / lowerGainDivisor, isSoundPlaying: true }, () => {
      playSound(this.state.ear, this.state.currentGainValue, defaultFrequency, defaultSoundLength, 0, () => this.setState({ isSoundPlaying: false }));
    });
  };

  /**
   * Function that is used when the "previous sound" button is pressed.
   */
  callbackPreviousSound = () => {
    const newGain = this.state.currentGainValue * lowerGainDivisor;
    this.setState({ currentGainValue: newGain, isSoundPlaying: true }, () => {
      playSound(this.state.ear, this.state.currentGainValue, defaultFrequency, defaultSoundLength, 0, () => this.setState({ isSoundPlaying: false }));
    });
  };

  /**
   * Function that is used when the "previous site" button ios pressed.
   */
  callbackPreviousTest = () => {
    this.setState({ currentGainValue: defaultGainValue, isTestActive: false });
    if (this.state.ear === 'right' && this.state.withHearingProtection === false) {
      this.setState({ ear: 'left' });
    } else if (this.state.ear === 'left' && this.state.withHearingProtection === true) {
      this.props.back();
      this.setState({ ear: 'right', withHearingProtection: false });
    } else if (this.state.ear === 'right' && this.state.withHearingProtection === true) {
      this.setState({ ear: 'left' });
    }
  };

  render() {
    return (
      <TestExecutionContainer>
        <LeftContainer
          style={{
            backgroundColor: this.state.isTestActive ? this.theme.palette.neutralLight : this.state.ear === 'left' ? this.theme.palette.blue : this.theme.palette.red
          }}
        >
          <ExplanationWrapper>
            <HeaderContainer>
              <div dangerouslySetInnerHTML={{ __html: this.switchHeadline() }} />
            </HeaderContainer>
            {!this.state.isTestActive && (
              <StyledList>
                {this.state.withHearingProtection === true && (
                  <li>
                    <ExplanationContainer>{I18n.get().t('Test_Execution_One')}</ExplanationContainer>
                  </li>
                )}
                <li>
                  <ExplanationContainer>{I18n.get().t('Test_Execution_Two')}</ExplanationContainer>
                </li>
                <li>
                  <ExplanationContainer>{I18n.get().t('Test_Execution_Three')}</ExplanationContainer>
                </li>
                <li>
                  <ExplanationContainer>{I18n.get().t('Test_Execution_Four')}</ExplanationContainer>
                </li>
                <li>
                  <ExplanationContainer>{I18n.get().t('Test_Execution_Five')}</ExplanationContainer>
                </li>
              </StyledList>
            )}
          </ExplanationWrapper>
          <ButtonContainer>
            <MarginBottom>
              <DefaultButton
                styles={this.abortButtonStyles}
                style={{
                  backgroundColor: this.state.isTestActive ? this.theme.palette.neutralLight : this.state.ear === 'left' ? this.theme.palette.blue : this.theme.palette.red,
                  color: this.state.isTestActive ? this.theme.palette.black : this.theme.palette.white
                }}
                text={I18n.get().t('Test_Instructions_Abort')}
                onClick={this.props.abort}
              />
              {!this.state.isTestActive && (
                <DefaultButton
                  styles={this.beginButtonStyles}
                  style={{ color: this.state.isTestActive ? this.theme.palette.neutralLight : this.state.ear === 'left' ? this.theme.palette.blue : this.theme.palette.red }}
                  text={!this.state.isTestActive ? I18n.get().t('Test_Execution_BeginButton') : I18n.get().t('Test_Execution_TestIsActive')}
                  onClick={() => this.callbackStartTest()}
                  disabled={this.state.isTestActive}
                />
              )}
            </MarginBottom>
            {!(this.state.ear === 'left' && this.state.withHearingProtection === false) && (
              <DefaultButton
                styles={this.state.isTestActive ? this.previousTestButtonStylesTestActive : this.previousTestButtonStylesLeft}
                iconProps={{ iconName: 'arrow-left-white' }}
                disabled={this.state.isCountdownRunning || this.state.isSoundPlaying}
                text={I18n.get().t('TestExecution_PreviousTest')}
                onClick={() => this.callbackPreviousTest()}
              />
            )}
          </ButtonContainer>
        </LeftContainer>
        <RightContainer>
          <UserIconContainer>
            <CountdownOverlay fontColor={this.theme.palette.white} visible={this.state.isCountdownRunning}>
              {this.state.countdownValue}
            </CountdownOverlay>
            <FontIcon hidden={!(this.state.ear === 'left' && this.state.withHearingProtection)} iconName="active-left-filled" />
            <FontIcon hidden={!(this.state.ear === 'left' && !this.state.withHearingProtection)} iconName="active-left-outlined" />
            <FontIcon hidden={!(this.state.ear === 'right' && this.state.withHearingProtection)} iconName="active-right-filled" />
            <FontIcon hidden={!(this.state.ear === 'right' && !this.state.withHearingProtection)} iconName="active-right-outlined" />
          </UserIconContainer>
          <SoundIconWrapper>
            <SoundIconContainer hidden={!this.state.isSoundPlaying} backgroundColor={this.theme.palette.green}>
              <FontIcon className={this.classNames.whiteIcon} iconName="music-icon" />
            </SoundIconContainer>
          </SoundIconWrapper>
          <ButtonContainer>
            <MarginBottom>
              <PrimaryButton
                styles={this.toneButtonStyles}
                style={{ marginRight: '26px', backgroundColor: this.state.isTestActive ? this.theme.palette.themePrimary : this.theme.palette.neutralLight }}
                text={I18n.get().t('Test_Execution_ToneHeardButton')}
                onClick={() => this.callbackToneHeard()}
                disabled={this.state.isCountdownRunning || !this.state.isTestActive || this.state.isSoundPlaying}
              />
              <PrimaryButton
                styles={this.toneButtonStyles}
                style={{ backgroundColor: this.state.isTestActive ? this.theme.palette.themePrimary : this.theme.palette.neutralLight }}
                text={I18n.get().t('Test_Execution_ToneNotHeardButton')}
                onClick={() => this.finishCurrentTest()}
                disabled={this.state.isCountdownRunning || !this.state.isTestActive || this.state.isSoundPlaying}
              />
            </MarginBottom>
            <DefaultButton
              styles={this.previousSoundButtonStyles}
              iconProps={{
                iconName:
                  this.state.isCountdownRunning || !this.state.isTestActive || this.state.isSoundPlaying || this.state.currentGainValue >= defaultGainValue
                    ? 'arrow-left-gray'
                    : 'arrow-left-black'
              }}
              text={I18n.get().t('TestExecution_PreviousSoundLevel')}
              disabled={this.state.isCountdownRunning || !this.state.isTestActive || this.state.isSoundPlaying || this.state.currentGainValue >= defaultGainValue}
              onClick={() => this.callbackPreviousSound()}
            />
          </ButtonContainer>
        </RightContainer>
      </TestExecutionContainer>
    );
  }
}
