import React from 'react';
import styled from 'styled-components';
import { PrimaryButton, getTheme, Label } from 'office-ui-fabric-react';
import I18n from '../helper/Localization';

const CurrentPageDisplay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: 1px solid ${(props) => props.borderColor};
  min-height: 25px;
  max-height: 25px;
  min-width: 30px;
  max-width: 30px;
  margin: 0 10px;
`;

const TableControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 60px 0 60px;
  @media only screen and (max-width: 1400px) {
    margin: 10px 20px 0 20px;
  }
`;

/**
 * Basic controls for paged table.
 */
export const PaginationControls = (props) => {
  const theme = getTheme();

  /**
   * Styles for the page control button.
   */
  const pageControlButtonStyles = {
    root: {
      backgroundColor: theme.palette.neutralPrimary,
      border: 'unset',
      borderRadius: '2px',
      height: 25,
      width: 30,
      minWidth: 0,
      padding: 0,
      margin: '0 10px'
    },
    rootHovered: {
      backgroundColor: 'none',
      border: 'unset'
    },
    rootPressed: {
      backgroundColor: 'none',
      border: 'unset'
    },
    rootFocused: {
      border: 'unset',
      outline: 0
    }
  };

  /**
   * Styles for the table control button.
   */
  const tableControlButtonStyles = {
    root: {
      backgroundColor: theme.palette.neutralPrimary,
      border: 'unset',
      borderRadius: '2px',
      height: 25,
      width: 75,
      minWidth: 0,
      margin: '0 10px'
    },
    rootHovered: {
      backgroundColor: 'none',
      border: 'unset',
      outline: 0
    },
    rootPressed: {
      backgroundColor: 'none',
      border: 'unset',
      outline: 0
    },
    rootFocused: {
      border: 'unset',
      outline: 0
    }
  };
  return (
    <TableControls>
      <PrimaryButton disabled={props.currentPage <= 1} styles={tableControlButtonStyles} text={I18n.get().t('TableControls_PageBackward')} onClick={props.navigateBackwards} />
      <PrimaryButton disabled={props.currentPage === 1} styles={pageControlButtonStyles} text="1" onClick={props.navigateToBegin} />
      <CurrentPageDisplay borderColor={theme.palette.neutralPrimary}>{props.currentPage}</CurrentPageDisplay>
      <Label>...</Label>
      <PrimaryButton disabled={props.currentPage === props.maxPages} styles={pageControlButtonStyles} text={props.maxPages} onClick={props.navigateToEnd} />
      <PrimaryButton
        disabled={props.currentPage >= props.maxPages}
        styles={tableControlButtonStyles}
        text={I18n.get().t('TableControls_PageForward')}
        onClick={props.navigateForward}
      />
    </TableControls>
  );
};
