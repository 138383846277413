import React, { useEffect } from 'react';
import OfflineApp from './OfflineApp';
import OnlineApp from './OnlineApp';
import { useStoreActions, useStoreState } from 'easy-peasy';

/**
 * Decide if the offline or the online app is used.
 */
const AppInitilizer = () => {
  /**
   * Retrieve the store state of the offline mode.
   */
  const isOfflineModeEnabled = useStoreState((state) => state.ui.isOfflineModeEnabled);
  /**
   * Retrieve the action to update the store offline mode state.
   */
  const updateIsOfflineModeEnabled = useStoreActions((actions) => actions.ui.updateIsOfflineModeEnabled);

  /**
   * Update to offline mode if no internet connection exists.
   */
  useEffect(() => {
    if (!navigator.onLine && !isOfflineModeEnabled) {
      updateIsOfflineModeEnabled(true);
    }
  }, [isOfflineModeEnabled, updateIsOfflineModeEnabled]);

  return isOfflineModeEnabled ? <OfflineApp /> : <OnlineApp />;
};

export default AppInitilizer;
