import React, { useEffect, useState } from 'react';
import { HearingTest } from './HearingTest';
import { postToApi } from '../../helper/ApiHelper';
import { msalApp } from '../../helper/AuthHelper';
import History from '../BrowserHistory';

/**
 * component that handles the complete hearing test
 */
export const OnlineHearingTest = (props) => {
  /**
   * Name of the wearer.
   */
  const [wearerName, setWearerName] = useState('');

  /**
   * Initialize the hearing test.
   */
  useEffect(() => {
    if (!props || !props.location || !props.location.orderPositionId) {
      // If user is not in offline mode and did not provide order pos id, deny access.
      History.replace('/orders');
    }
    if (props && props.location && props.location.wearer) {
      // If there is a wearer name given by location, set the state.
      setWearerName(props.location.wearer);
    }
  }, [props]);

  /**
   * submit the hearing test result
   */
  const submitTestResult = async (
    dbLeftWithoutProtection,
    dbLeftWithProtection,
    dbRightWithoutProtection,
    dbRightWithProtection,
    insulationLeft,
    insulationRight,
    isSuccessfulLeft,
    isSuccessfulRight
  ) => {
    const _msalApp = await msalApp();
    const name = _msalApp.getAccount().name;
    const reqBody = {
      dateExecuted: new Date().toISOString(),
      measurementLeftWithoutProtection: dbLeftWithoutProtection,
      measurementLeftWithProtection: dbLeftWithProtection,
      measurementRightWithoutProtection: dbRightWithoutProtection,
      measurementRightWithProtection: dbRightWithProtection,
      insulationLeft: insulationLeft,
      insulationRight: insulationRight,
      isSuccessfulLeft: isSuccessfulLeft,
      isSuccessfulRight: isSuccessfulRight,
      orderPositionId: props.location.orderPositionId,
      testerName: name
    };
    try {
      await postToApi('v1.0/TestResult', reqBody);
    } catch (error) {
      console.error(error);
    }
  };

  return <HearingTest isOfflineModeEnabled={false} finishAndSaveTest={submitTestResult} wearerName={wearerName} />;
};
