import React, { useState, useEffect } from 'react';
import { HearingTest } from './HearingTest';
import { useStoreState } from 'easy-peasy';

/**
 * component that handles the complete hearing test
 */
export const OfflineHearingTest = () => {
  /**
   * State of the email address of the test executor (needed for offline mode).
   */
  const [executorEmail, setExecutorEmail] = useState('');

  /**
   * State of the notes the test executor writes to assign the test result to an order (needed for offline mode).
   */
  const [offlineNotes, setOfflineNotes] = useState('');

  /**
   * Retrieve the store state of the offline mode.
   */
  const isOfflineModeEnabled = useStoreState((state) => state.ui.isOfflineModeEnabled);

  /**
   * Initialize the hearing test.
   */
  useEffect(() => {
    if (isOfflineModeEnabled) {
      const lastLoggedInUserMail = localStorage.getItem('lastLoggedInUserMail');
      setExecutorEmail(lastLoggedInUserMail);
    }
  }, [isOfflineModeEnabled]);

  /**
   * Save the test result in local browser storage.
   */
  const saveTestResultLocally = (
    dbLeftWithoutProtection,
    dbLeftWithProtection,
    dbRightWithoutProtection,
    dbRightWithProtection,
    insulationLeft,
    insulationRight,
    isSuccessfulLeft,
    isSuccessfulRight
  ) => {
    // Retrieve the currently stored offline test results.
    const currentStoredResults = JSON.parse(localStorage.getItem('offlineTestResults'));
    // Create the new test result.
    const newTestResult = {
      executorEmail: executorEmail,
      notes: offlineNotes,
      dateExecuted: new Date().toISOString(),
      measurementLeftWithoutProtection: dbLeftWithoutProtection,
      measurementLeftWithProtection: dbLeftWithProtection,
      measurementRightWithoutProtection: dbRightWithoutProtection,
      measurementRightWithProtection: dbRightWithProtection,
      insulationLeft: insulationLeft,
      insulationRight: insulationRight,
      isSuccessfulLeft: isSuccessfulLeft,
      isSuccessfulRight: isSuccessfulRight
    };
    // Create the new array of offline test results.
    let newStoredResults;
    if (currentStoredResults) {
      newStoredResults = [...currentStoredResults, newTestResult];
    } else {
      newStoredResults = [newTestResult];
    }
    // Save all offline test results in the local storage.
    localStorage.setItem('offlineTestResults', JSON.stringify(newStoredResults));
  };

  return (
    <HearingTest
      startProgress={-1}
      isOfflineModeEnabled={true}
      finishAndSaveTest={saveTestResultLocally}
      updateOfflineNotes={(newValue) => setOfflineNotes(newValue)}
      offlineNotes={offlineNotes}
      updateExecutorMail={(newValue) => setExecutorEmail(newValue)}
      executorEmail={executorEmail}
    />
  );
};
